import React, { useEffect } from "react";
import LandingPage from "./landing/";
import Homepage from "../pages/Home";
import ServiceDetailPage from "../views/Services/ServiceDetail";
import CustomerExperienceAndDesignPage from "../views/Services/Customer-experience-and-design";
import DigitalProductEngineeringPage from "../views/Services/Digital-product-engineering";
import DigitalEngineeringServicesPage from "../views/Services/Digital-engineering-and-services";
import MVPServicesPage from "../views/Services/Mvp-services";
import DataAndAnalyticsPage from "../views/Services/Data-and-analytics";
import ApplicationServices from "../views/Services/Application-services";
import ContactUs from "./contact-us/ContactUs";
import Careers from "./careers/Careers";
import ComingSoon from "./comingSoon/ComingSoon";
import BlogListingPage from "./Blogs/listing";
import BlogDetailPage from "./Blogs/blog-detail";
import AboutUs from "./about/AboutUs";
import CaseStudy from "./case-study/CaseStudy";
import CaseStudyDetail from "./case-study/CaseStudyDetail";
import Header from "../components/Header";
import Footer from "../components/Footer";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  }, [pathname]);

  return null;
}
function Root() {
  return (
    <>
      <BrowserRouter>
        {window.location.pathname !== "/" && <Header />}
        <ScrollToTop />
        <Routes>
          {/* protected routes */}
          <Route exact path="/home" element={<LandingPage />} />
          <Route exact path="/" element={<ComingSoon />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/insights" element={<BlogListingPage />} />
          <Route path="/blog-detail/:id" element={<BlogDetailPage />} />
          <Route path="/company-overview" element={<AboutUs />} />
          <Route path="/client-success" element={<CaseStudy />} />
          <Route path="/case-study-detail" element={<CaseStudyDetail />} />
          <Route path="/services" element={<ServiceDetailPage />} />
          <Route path="/services/services-detail" element={<Homepage />} />
          <Route
            path="/services/customer-experience-and-design"
            element={<CustomerExperienceAndDesignPage />}
          />
          <Route
            path="/services/digital-product-engineering"
            element={<DigitalProductEngineeringPage />}
          />
          <Route
            path="/services/digital-engineering-and-services"
            element={<DigitalEngineeringServicesPage />}
          />
          <Route
            path="/services/data-and-analytics"
            element={<DataAndAnalyticsPage />}
          />
          <Route
            path="/services/application-services"
            element={<ApplicationServices />}
          />
          <Route
            path="/services/minimum-viable-product-development"
            element={<MVPServicesPage />}
          />
        </Routes>
        {window.location.pathname !== "/" && <Footer />}
      </BrowserRouter>
      {/* <Loader showLoader={showLoader} /> */}
    </>
  );
}

export default Root;
