import React, { useEffect, useState, useRef } from 'react';
import "../../assets/css/pages/careers.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import CustomLink from "../../components/Common/CustomLink";
import ServiceContentCard from "../../components/ServiceContentCard";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import careersBanner from "../../assets/images/careers/careerBanner.png";
import officeLocationMap from "../../assets/images/careers/officeLocationMap.png";
import developmentIcon from "../../assets/images/icon/developmentIcon.svg";
import { ReactComponent as ReferIcon } from "../../assets/images/icon/refer.svg";
import hi5Icon from "../../assets/images/icon/hi5Icon.svg";
import graphIcon from "../../assets/images/icon/graphIcon.svg";
import CardImage from "../../assets/images/services-detail/card/sampleImg.jpeg";
import CardImage1 from "../../assets/images/case-study/application-services-vertical.jpg";
import CardImage2 from "../../assets/images/case-study/data-and-Analytics-vertical.jpg";
import CardImage3 from "../../assets/images/case-study/digital-engineering-and-services-vertical.jpg";
import CardImage4 from "../../assets/images/case-study/MVP-vertical.jpg";
import VtravelLogo from "../../assets/images/case-study/vtravel-logo.png";
import SliderImg1 from "../../assets/images/careers/sliderImg1.png";
import SliderImg2 from "../../assets/images/careers/sliderImg2.png";
import { ReactComponent as LocationIcon } from "../../assets/images/icon/location.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icon/arrowIcon.svg";

import ShiftLeftArrow from "../../assets/images/icon/shift-left-arrow.svg";
import ShiftRightArrow from "../../assets/images/icon/shift-right-arrow.svg";


const Careers = () => {
    const [scroll, setScroll] = useState(false);
    const [scrolDenefitsSection, setScrolDenefitsSection] = useState(false);
    const [scrollServicesSlider, setScrollServicesSlider] = useState(false);

    const [slideIndex, setSlideIndex] = useState(0);
    const sliderRef = useRef();

    useEffect(() => {
        const element = document.getElementById("servicesSliderSection");
        let pos = element.offsetTop - (window.innerHeight / 2);

        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScrollServicesSlider(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrollServicesSlider(false)
            })
        }
    }, []);

    useEffect(() => {
        let pos3 = document.getElementById("denefitsSection").offsetTop - (window.innerHeight / 2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos3) {
                setScrolDenefitsSection(true);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrolDenefitsSection(false)
            })
        }
    }, []);

    function animateFrom(elem, direction, index) {
        //console.log(index, "index")
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        // elem.style.transform = "translate(" + x + "px, " + y + "px)";
        // elem.style.backgroundPosition = x + "px, " + y + "px";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });

    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem, null, index) },
                onEnterBack: function () { animateFrom(elem, -1, index) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
        gsap.utils
            .toArray(".card img")
            .forEach((section, i) => {
                const heightDiff =
                    section.offsetHeight - section.parentElement.offsetHeight;

                gsap.fromTo(
                    section,
                    {
                        y: section.parentElement.offsetHeight - 300
                    },
                    {
                        y: -230,
                        ease: "none",
                        scrollTrigger: {
                            trigger: section.parentElement,
                            scrub: true
                        }
                    }
                );
            });
    }, [])

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" disabled={slideIndex === 4} className={className} onClick={onClick}>
                <img src={ShiftRightArrow} />
            </button>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" disabled={slideIndex === 0} className={className} onClick={onClick}><img src={ShiftLeftArrow} /></button>
        );
    }

    const settings = {
        dots: true,
        fade: false,
        autoplay: false,
        infinite: false,
        autoplaySpeed: 4000,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        centerMode: false,
        centerPadding: '0px',
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]

    };
    return <div className="wrapper">
        <div className="careers-wrapper home-wrapper">
            <div className="careers-page home-page page-about-us">
                <div className="section-1 first" >
                    <div className="product-banner" >
                        <div className="banner-section" >
                            <div className="container banner-container" >
                                <div className="left-content first-paragraph" >
                                    <h5 className="tagline">Careers</h5>
                                    <h1 className="tagline">Start your career at <br />Digialpha today</h1>
                                    <p className="banner-para">Founded in 2013, Digialpha is a leading global technology consultancy that integrates strategy, design and software engineering to enable enterprises.</p>
                                    <CustomLink to="#" renderSvg={true}>
                                        <span>Explore all jobs</span>
                                    </CustomLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="custom-link white-wrapper" href="/services/customer-experience-and-design">
                        <h5>Featured Whitepaper </h5>
                        <h4><span>A tool-first, automated approach to scaling your </span>
                            <span>DevSecOps organization
                                
                            </span>
                        </h4>
                    </a>
                </div>
                <div id="denefitsSection" className={scrolDenefitsSection ? "differentiators-section animate" : "differentiators-section"}>
                    <div className="container pd-top">
                        <h5><div className='line' ><div className='lineIn'>Benefits at Digialpha </div></div></h5>
                        <h1><div className='line' ><div className='lineIn'>What benefits you will get here</div></div></h1>
                        <div className="content-wrapper d-flex">
                            <div className="row">
                                <div className="col diff-content">
                                    <div className="icon-wrap">
                                        <img src={developmentIcon} alt="rifht-size-icon" />
                                    </div>
                                    <h3>Growth and Learnings</h3>
                                    <p>We believe in cross-cutting growth and encourage participation in all aspects of our work.</p>
                                </div>
                                <div className="col diff-content">
                                    <div className="icon-wrap"><img src={hi5Icon} /></div>
                                    <h3>Passion and Respect</h3>
                                    <p>Our exuberance for work stems from respecting a variety of viewpoints and embracing the unknown.</p>
                                </div>
                                <div className="col diff-content">
                                    <div className="icon-wrap"><img src={hi5Icon} /></div>
                                    <h3>Passion and Respect</h3>
                                    <p>Our exuberance for work stems from respecting a variety of viewpoints and embracing the unknown.</p>
                                </div>
                                <div className="col diff-content">
                                    <div className="icon-wrap"><img src={graphIcon} /></div>
                                    <h3>Work with fun</h3>
                                    <p>Over the last 10 years plus our Customers have highly appreciated our solutions and delivery quality. All work we delivered is referenceable.</p>
                                </div>
                                <div className="col diff-content">
                                    <div className="icon-wrap"><img src={graphIcon} /></div>
                                    <h3>Care culture</h3>
                                    <p>Over the last 10 years plus our Customers have highly appreciated our solutions and delivery quality. All work we delivered is referenceable.</p>
                                </div>
                                <div className="col diff-content">
                                    <div className="icon-wrap"><img src={graphIcon} /></div>
                                    <h3>Care culture</h3>
                                    <p>Over the last 10 years plus our Customers have highly appreciated our solutions and delivery quality. All work we delivered is referenceable.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ecosystem-section careers-location-section" >
                    <div className="row container ">
                        <div className="col-1 ecosystem-content">
                            <h5>Our Locations</h5>
                            <h1>4 countries and wide range of experiences.</h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has  </p>
                            <div className="progress-bar mobileHide">
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                            </div>
                        </div>
                        <div className="col-2 ecosystem-img">
                            <img src={officeLocationMap} />

                        </div>
                    </div>
                </div>
                <div className="section-service-card">
                    <div className="container">
                        <div className="cInnerContent">
                            <h5>Learn, Implement, Repeat</h5>
                            <h1 className='titleBig'>Working at Digialpha</h1>
                            <div className="feature-row row">
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage1} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage2} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage3} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage4} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                                <div className="col col1">
                                    <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='graySection featured-jobs-section' >
                    <div className='container' >
                        <div className='row no-gutters section-heading'>
                            <div className='col left-content'>
                                <h5>Featured Jobs</h5>
                                <h1 className='titleBig' >Join us in career progression.</h1>
                            </div>
                            <div className='col right-content'>
                                <button type='button'><ReferIcon /> Refer a friend</button>
                            </div>
                        </div>
                        <div className='row' >
                            <div className='col' >
                                <div className='whiteCard' >
                                    <h4>Senior Product Designer</h4 >
                                    <p>Exp: 7-8 years</p>
                                    <p className='cardbtm' >
                                        <span><LocationIcon /> Pune | Singapore</span>
                                        <ArrowIcon className='arrowIcon' />
                                    </p>
                                </div>
                            </div>
                            <div className='col' >
                                <div className='whiteCard' >
                                    <h4>Business Development Executive</h4 >
                                    <p>Exp: 3-5 years</p>
                                    <p className='cardbtm' >
                                        <span><LocationIcon /> Pune | Singapore</span>
                                        <ArrowIcon className='arrowIcon' />
                                    </p>
                                </div>
                            </div>
                            <div className='col' >
                                <div className='whiteCard' >
                                    <h4>Senior Backend Developer</h4 >
                                    <p>Exp: 7-8 years</p>
                                    <p className='cardbtm' >
                                        <span><LocationIcon /> Pune | Singapore</span>
                                        <ArrowIcon className='arrowIcon' />
                                    </p>
                                </div>
                            </div>
                            <div className='col' >
                                <div className='whiteCard' >
                                    <h4>Quality Analyst Lead</h4 >
                                    <p>Exp: 7-8 years</p>
                                    <p className='cardbtm' >
                                        <span><LocationIcon /> Pune | Singapore</span>
                                        <ArrowIcon className='arrowIcon' />
                                    </p>
                                </div>
                            </div>
                            <div className='col' >
                                <div className='whiteCard' >
                                    <h4>Quality Analyst Lead</h4 >
                                    <p>Exp: 3-5 years</p>
                                    <p className='cardbtm' >
                                        <span><LocationIcon /> Pune | Singapore</span>
                                        <ArrowIcon className='arrowIcon' />
                                    </p>
                                </div>
                            </div>
                            <div className='col' >
                                <div className='whiteCard viewAllLink' >
                                    <Link to="#" >View all <ArrowIcon className='arrowIcon' /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="servicesSliderSection" className={scrollServicesSlider ? "services-slider-section animate" : "services-slider-section"} >
                    <div className="container">
                        <div className="section-title">
                            <h2>Life at Digialpha</h2>
                        </div>
                    </div>
                    <div className="slider-wrapper">
                        <div className="container" >
                            <Slider {...settings} ref={sliderRef} afterChange={(index) => {
                                setSlideIndex(index)
                            }}>
                                <div className="col slider-item">
                                    <img src={SliderImg1} alt="slider image" />
                                </div>
                                <div className="col slider-item">
                                    <img src={SliderImg2} alt="slider image" />
                                </div>
                                <div className="col slider-item">
                                    <img src={SliderImg1} alt="slider image" />
                                </div>
                                <div className="col slider-item">
                                    <img src={SliderImg2} alt="slider image" />
                                </div>
                                <div className="col slider-item">
                                    <img src={SliderImg1} alt="slider image" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Careers;