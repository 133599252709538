import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";
import { accordianList } from "../../src/data/faq/accordianList";
import CustomLink from "../components/Common/CustomLink";
import Accordian from "../components/Accordion";
const Careers = () => {
    const [openedAccordian, setOpenedAccordian] = useState(false);
    const [isExpandAccordian, setIsExpandAccoridan] = useState(false);
    const handleOpenClose = (id) => {
        if (id === openedAccordian) {
            setOpenedAccordian(null);
        } else {
            setOpenedAccordian(id);
        }
    };
    return (
        <div className="careers-section">
            <div className="careers-section-wrap">
                <div className="container">
                    <div className="section-title">
                        <h2>Careers</h2>
                        <h3>Start your career
                            at Digialpha today</h3>
                        <div className="content">
                            <p>Founded in 2013, Digialpha is a leading global technology consultancy that integrates strategy, design and software engineering to enable enterprises.</p>
                        </div>
                    </div>
                    <div className="accordion-wrapper">
                        <div className="accordion">
                            {accordianList.map((faq, index) => {
                                return <Accordian
                                    handleOpenClose={handleOpenClose}
                                    openedAccordian={openedAccordian}
                                    id={index}
                                    key={"FAQ" + index}
                                    title={faq.title}
                                    content={faq.description}
                                    button={faq.button}
                                    className={`card-content-wrap`}
                                />
                            })}

                        </div>

                        <div className="explore-btn">
                            <CustomLink to="#" renderSvg={true}>
                                <h5>Explore all roles </h5>
                            </CustomLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Careers;
