import { affiliateConstants } from "../Constants/affiliateConstants";

const initialState = {
  affiliatePremiumSignupData: null,
  affiliateUpgradeData: null,
  getAffiliateUserData : null,
  upgradeErrorMessage   : null
};

const affiliateReducer = (state = initialState, action) => {
  switch (action.type) {
 
    case affiliateConstants.AFFILIATE_PREMIUM_SIGNUP_REQUEST:
      return { ...state,affiliatePremiumSignupData : null  };
    case affiliateConstants.AFFILIATE_PREMIUM_SIGNUP_SUCCESS:
      return { ...state, affiliatePremiumSignupData: action.response.status };
    case affiliateConstants.AFFILIATE_PREMIUM_SIGNUP_FAILURE:
      return { ...state,affiliatePremiumSignupData : null , affiliatePremiumSignupData:null};


    case affiliateConstants.GET_AFFILIATE_USER_BY_MEMBER_ID_REQUEST:
      return { ...state, getAffiliateUserData: null , affiliateUpgradeData: null , upgradeErrorMessage : null};
    case affiliateConstants.GET_AFFILIATE_USER_BY_MEMBER_ID_SCUCCES:
      return { ...state, getAffiliateUserData: action?.response};
    case affiliateConstants.GET_AFFILIATE_USER_BY_MEMBER_ID_FAILURE:
      return {
        ...state,getAffiliateUserData: action?.error?.response
      };

      case affiliateConstants.AFFILIATE_UPGRADE_MEMBERSHIP_REQUEST:
        return { ...state, affiliateUpgradeData: null , upgradeErrorMessage: null };
      case affiliateConstants.AFFILIATE_UPGRADE_MEMBERSHIP_SUCCESS:
        return { ...state, affiliateUpgradeData: action?.response?.status};
      case affiliateConstants.AFFILIATE_UPGRADE_MEMBERSHIP_FAILURE:
        console.log(" action?.error?.response?.data" ,  action?.error?.response?.data?.response?.response_message);
        return {
          ...state,
          affiliateUpgradeData:null, upgradeErrorMessage : action?.error?.response?.data?.response?.response_message
        };  

    default:
      return state;
  }
};

export default affiliateReducer;
