import React, { useEffect, useState } from "react";
import corevalues1 from "../assets/images/core-values/1.png";
import corevalues2 from "../assets/images/core-values/2.png";
import corevalues3 from "../assets/images/core-values/3.png";
import corevalues4 from "../assets/images/core-values/4.png";
import corevalues5 from "../assets/images/core-values/5.png";
import corevalues6 from "../assets/images/core-values/6.png";
import "../assets/css/component/core-values.scss";

const CoreValues = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        const element = document.getElementById("corporate-values");
        let pos = element.offsetTop - (window.innerHeight/2);
        
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }        
      }, []);
    return (
        <div id="corporate-values" className={scroll ? "core-values-section animate" : "core-values-section"}>
            <div className="container">
                <div className="section-title">
                    <h2>
                        <span className="line" ><span className="lineIn" >Our core values</span></span>
                    </h2>
                    <h3>
                        <span className="line" ><span className="lineIn" >Our values form the bedrock of our culture and define how we work together to serve our clients.</span></span>
                    </h3>
                </div>
                <div className="card-group">
                    <div className="row">
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues1} alt="core values" />
                                    </div>
                                    <h4>Accountability</h4>
                                    <div className="content">
                                        <p>
                                            We've been at the forefront of technology innovation as a thought leader over the past 8 years.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues2} alt="core values" />
                                    </div>
                                    <h4>Autonomous teams</h4>
                                    <div className="content">
                                        <p>
                                            We've been at the forefront of technology innovation as a thought leader over the past 8 years.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues3} alt="core values" />
                                    </div>
                                    <h4>Customer Centricity</h4>
                                    <div className="content">
                                        <p>
                                            We've been at the forefront of technology innovation as a thought leader over the past 8 years.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues4} alt="core values" />
                                    </div>
                                    <h4>Curiosity</h4>
                                    <div className="content">
                                        <p>
                                            We've been at the forefront of technology innovation as a thought leader over the past 8 years.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues5} alt="core values" />
                                    </div>
                                    <h4>Integrity</h4>
                                    <div className="content">
                                        <p>
                                            We've been at the forefront of technology innovation as a thought leader over the past 8 years.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col card-col">
                            <div className="card">
                                <div className="card-content">
                                    <div className="image">
                                        <img src={corevalues6} alt="core values" />
                                    </div>
                                    <h4>Pursuit of Excellence</h4>
                                    <div className="content">
                                        <p>
                                            We've been at the forefront of technology innovation as a thought leader over the past 8 years.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CoreValues;