import React, { useEffect } from "react";
import CustomLink from "../components/Common/CustomLink";
import Card1 from "../assets/images/services-detail/card/service-card.png"
import sampleImage from "../assets/images/services-detail/card/sampleImg.jpeg"
const ServiceContentCard = ({ clientLogo, sectionClass, ContentClass, cardImage, title, subTitle, content, buttontext, buttonUrl }) => {
    return (
        <div className="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
            <div className={`featured-image-container ipsGrid_span5 gs_reveal ${sectionClass}`}>
                <div className="card">
                    <img src={cardImage} />
                   {clientLogo && <div className="logo-image">
                        <img src={clientLogo} alt="client logo" />
                    </div>}
                </div>
            </div>
            <div className={`content-wrap ipsGrid_span7 ${ContentClass}`}>
                {title && <h3 className="orange-text">{title}</h3>}
                <h2 className="heading_large gs_reveal">{subTitle}</h2>
                <p className="gs_reveal">{content}</p>
                <div className="learn-more-btn">
                    <CustomLink to={buttonUrl} className="gs_reveal" renderSvg={true}>
                        <h5>{buttontext}</h5>
                    </CustomLink>
                </div>
            </div>
        </div>
    );
};

export default ServiceContentCard;