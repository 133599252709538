import React, { useEffect, useRef, useState } from "react";
import GetInTouch from "../../components/GetInTouch";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import BannerContent from "../../components/BannerContent";
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/services-detail/card/service-detail-image.png";
import Blog from "../../components/Blog";
// import "../../assets/css/common.scss";
import "../../assets/css/pages/blog-listing.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  blogActions,
  featuredBlogActions,
} from "../../Stores/Actions/blogAction";
import { isEmpty } from "lodash";

const BlogListingPage = () => {
  const dispatch = useDispatch();

  // blog data from the store
  const blogData = useSelector(
    (state) => state?.blogReducer?.blogResults?.data
  );

  // featured blog data from the store       // As of now, we are not getting data from api response
  const featuredData = useSelector(
    (state) => state?.blogReducer?.featuredBlog?.data
  );

  useEffect(() => {
    let payload = {
      filter_by: [11],
      page: 1,
      posts_per_page: 3,
      order_by: "a-z",
    };
    dispatch(featuredBlogActions());
    dispatch(blogActions(payload));
  }, []);

  return (
    <div className="wrapper">
      <div className="page-blog-listing">
        {!isEmpty(featuredData) ? (
          <BannerContent
            BackgroundImages={featuredData?.[0]?.image}
            title={featuredData?.[0]?.title}
            subTitle={featuredData?.[0]?.description}
          >
            <CustomLink to="#" renderSvg={false} className="typeButton">
              Read the blog <RightArrow className="right-arrow" />
            </CustomLink>
          </BannerContent>
        ) : (
          <div></div>
        )}

        <Blog
          data={blogData}
          title={"All Insights"}
          showAll
          className="gray-section"
          showFilters={true}
        />
        {/* <Pagination /> */}
        <GetInTouch />
      </div>
    </div>
  );
};
export default BlogListingPage;
