import React from "react";
function Accordian({
  className,
  content,
  title,
  openedAccordian,
  id,
  handleOpenClose,
}) {
    //debugger;
  return (
    <div className="card" key={title}>
      <div className={'card-title'}>
      <button
        onClick={() => handleOpenClose(id)}
        className={id === openedAccordian ? 'faq-btn open-btn' : 'faq-btn'}> 
          {title}
        </button>
      </div>
      <div className={ id === openedAccordian ? `${className} open-accordion` : className}>
        <div className="card-content">
        <p> {content}{" "} </p>
        </div>
      </div>
    </div>
  );
}

export default Accordian;
