import { useEffect } from "react";
import { Link } from "react-router-dom";
import CustomLink from "../components/Common/CustomLink";
import FooterLogoPath from "../assets/images/logo/footer-logo.svg";
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as RightArrow2 } from "../assets/images/icon/right-arrow2.svg";
import { ReactComponent as LeftArrow2 } from "../assets/images/icon/left-arrow2.svg";
import { ReactComponent as Sort } from "../assets/images/icon/sorting.svg";
import BlogImage from "../assets/images/tmp/blog1.webp";
import BlogMobileImage from "../assets/images/tmp/blog-mobile-image.png";
import CheckboxMenu from "../components/Common/MultipleSelect";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import "../assets/css/component/blog.scss";
import "../assets/css/menu.scss";
import "../assets/JS/index.js";

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <a>
        <LeftArrow2 />
      </a>
    );
  }

  if (type === "next") {
    return (
      <a>
        <RightArrow2 />
      </a>
    );
  }

  return originalElement;
};
const Blog = ({ isHome, data, title, showAll, className, showFilters }) => {
  const onCheckboxChange = (selection) => {
    console.log(selection);
  };
  return (
    <div className={`blog-section ${className || ""}`}>
      <div className="container">
        <ul className="menu">
          <li>
            <div className="row no-gutters">
              <div className="col blog-col mobile-hide"></div>
              <div className="col blog-col section-blog-col">
                <div className="section-title">
                  <h3>Blogs, News & Events</h3>
                  <h2>{title ? title : "Latest Insights"}</h2>
                </div>
              </div>
              <div className="col blog-col filter-col">
                {showFilters ? (
                  <div className="sortWrapper-main">
                    <div className="selectWrapper-main p-relative">
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={[
                            "Analyst Insight",
                            "Blog",
                            "Brochure",
                            "Case Study",
                            "eBook",
                            "White Paper",
                          ]}
                          value={["Analyst Insight", "Blog", "Brochure"]}
                          onChange={onCheckboxChange}
                          placeholder="Insight Type"
                        />
                      </div>
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={[
                            "Analyst Insight",
                            "Blog",
                            "Brochure",
                            "Case Study",
                            "eBook",
                            "White Paper",
                          ]}
                          value={["Analyst Insight", "Blog", "Brochure"]}
                          onChange={onCheckboxChange}
                          placeholder="Service"
                        />
                      </div>
                      <div className="selectWrapper">
                        <CheckboxMenu
                          options={[
                            "Analyst Insight",
                            "Blog",
                            "Brochure",
                            "Case Study",
                            "eBook",
                            "White Paper",
                          ]}
                          value={["Analyst Insight", "Blog", "Brochure"]}
                          onChange={onCheckboxChange}
                          placeholder="Industry"
                        />
                      </div>
                    </div>
                    <div className="sort-option">
                      <button type="button" className="custom-btn">
                        <Sort /> Sort by: <strong>Oldest</strong>
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </li>
          {data?.map((blog, index) => {
            return (
              <li key={blog?.id}>
                {/* <a
                  href="/blog-detail"
                  className="no-gutters row clickable-anchor menu__item"
                > */}
                <Link
                  to={`/blog-detail/${blog?.id}`}
                  className="no-gutters row clickable-anchor menu__item"
                >
                  <div className="col blog-col blog-mobile-image">
                    {/* <img src={blog.thumbImage} /> */}
                    <img src={blog?.image} alt="" />
                  </div>
                  {/* <div className="row"> */}
                  <div className="col blog-col menu__item-text blog-title">
                    <img src={blog?.image} alt="" height="450" width="400" />
                  </div>
                  <div className="col blog-col menu__item-text">
                    <h3>{blog.title}</h3>
                  </div>
                  <div className="col blog-col menu__item-text">
                    <p>{blog?.description}</p>
                  </div>
                  {/* </div> */}
                  {/* <span className="hover-img">
                                <img src={BlogImage} alt="hover image" />
                            </span> */}
                </Link>
                {/* </a> */}
              </li>
            );
          })}
        </ul>
        {showAll && (
          <div className="explore-btn">
            <div className="row no-gutters">
              <div className="col"></div>
              <div className="col">
                <CustomLink to="/insights" renderSvg={true}>
                  <h5>Explore all Insights </h5>
                </CustomLink>
                {/* <Link to="/blogs"> Explore the journal <RightArrow /></Link> */}
              </div>
              <div className="col"></div>
            </div>
          </div>
        )}
      </div>
      {/* <svg class="cursor" width="80" height="80" viewBox="0 0 80 80">
            <circle class="cursor__inner" cx="40" cy="40" r="20"/>
        </svg> */}
      {showFilters && (
        <div className="custom-pagination-wrap">
          <div className="container">
            <Pagination total={500} itemRender={itemRender} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
