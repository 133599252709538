import { preloader } from "./preloader";
import LocomotiveScroll from "locomotive-scroll";
import Menu from "./menu";

// menu (<nav> element)
document.addEventListener("DOMContentLoaded", function() {
    setTimeout(function(){
        if(document.querySelector('.services_menu') && document.querySelector('.services_menu').childElementCount > 0 && window.innerWidth > 767) {
            const menuEl = document.querySelector('.services_menu');
            // preload the images set as data attrs in the menu items
            preloader('.services_menu__item').then(() => {
                // initialize the smooth scroll
               // console.log(menuEl, "menuEl")
                // const scroll = new LocomotiveScroll({el: menuEl, smooth: true});
                // initialize custom cursor
                // const cursor = new Cursor(document.querySelector('.cursor'));
            
                // initialize menu
                new Menu(menuEl);
            });
        }
       
     }, 1000)
  });