export const blogConstants = {
  BLOG_REQUEST: "BLOG_REQUEST",
  BLOG_SUCCESS: "BLOG_SUCCESS",
  BLOG_FAILURE: "BLOG_FAILURE",

  FEATURED_BLOG_REQUEST: "FEATURED_BLOG_REQUEST",
  FEATURED_BLOG_SUCCESS: "FEATURED_BLOG_SUCCESS",
  FEATURED_BLOG_FAILURE: "FEATURED_BLOG_FAILURE",

  BLOG_DETAILS_REQUEST: "BLOG_DETAILS_REQUEST",
  BLOG_DETAILS_SUCCESS: "BLOG_DETAILS_SUCCESS",
  BLOG_DETAILS_FAILURE: "BLOG_DETAILS_FAILURE",
};
