export const affiliateConstants = {
    AFFILIATE_PREMIUM_SIGNUP_REQUEST : "AFFILIATE_PREMIUM_SIGNUP_REQUEST",
    AFFILIATE_PREMIUM_SIGNUP_SUCCESS:"AFFILIATE_PREMIUM_SIGNUP_SUCCESS",
    AFFILIATE_PREMIUM_SIGNUP_FAILURE:"AFFILIATE_PREMIUM_SIGNUP_FAILURE",

    AFFILIATE_UPGRADE_MEMBERSHIP_REQUEST:"AFFILIATE_UPGRADE_MEMBERSHIP_REQUEST",
    AFFILIATE_UPGRADE_MEMBERSHIP_SUCCESS:"AFFILIATE_UPGRADE_MEMBERSHIP_SUCCESS",
    AFFILIATE_UPGRADE_MEMBERSHIP_FAILURE:"AFFILIATE_UPGRADE_MEMBERSHIP_FAILURE",

    GET_AFFILIATE_USER_BY_MEMBER_ID_REQUEST : "GET_AFFILIATE_USER_BY_MEMBER_ID_REQUEST",
    GET_AFFILIATE_USER_BY_MEMBER_ID_SCUCCES:"GET_AFFILIATE_USER_BY_MEMBER_ID_SCUCCES",
    GET_AFFILIATE_USER_BY_MEMBER_ID_FAILURE:"GET_AFFILIATE_USER_BY_MEMBER_ID_FAILURE"

}
 