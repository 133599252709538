import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import debounce from 'lodash.debounce'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Blog from "../components/Blog";
import CustomLink from "../components/Common/CustomLink";
import GetInTouch from "../components/GetInTouch";
import { ReactComponent as RightArrow } from "../assets/images/icon/right-arrow.svg"
import companyLogo from "../assets/images/logo/company-logo-1.png"
import companyLogo2 from "../assets/images/logo/ccr.png"
import companyLogo3 from "../assets/images/client-logos/ford.png"
import rightSizeIcon from "../assets/images/icon/right-size.svg"
import humanIcon from "../assets/images/icon/human.svg"
import recordGraphic from "../assets/images/icon/record-graph.svg"
import regionalIcon from "../assets/images/icon/regional.svg"
import windowsIcon from "../assets/images/marque-img/windows.png"
import imbIcon from "../assets/images/marque-img/ibm.png"
import awsIcon from "../assets/images/marque-img/aws.png"
import salesForceIcon from "../assets/images/marque-img/salesforce.png"
import redHatIcon from "../assets/images/marque-img/red-hat.png"
import { ReactComponent as VideoIcon } from "../assets/images/icon/video-btn-icon.svg";
import { blogData } from "./data";
import Slider from "react-slick";
import "../assets/css/pages/home.scss";
import WhyBetaCraft from '../components/Common/WhyBetaCraft';

const Homepage = () => {
    const sliderRef = useRef();
    const OfferingsliderRef = useRef();
    const circleSliderRef = useRef();
    const circleSliderContentRef = useRef();
    // const tabSliderRef = useRef();

    const navigatiorClickHistoryRef = useRef(false);
    const [tabSliderRef, setTabSliderRef] = useState();
    const [tabThumbRef, setTabThumbRef] = useState();
    const [indexInit, setindexInit] = useState(0);
    const [scrollOn, setScrollOn] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const [scrollUp, setScrollUp] = useState(false);
    const [images, setImages] = useState([companyLogo2, companyLogo, companyLogo2, companyLogo]);
    const [isShown, setIsShown] = useState(false);
    const [stopBodyScroll, setStopBodyScroll] = useState(false);

    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
    const Offeringslider = {
        autoplay: false,
        dots: false,
        fade: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    autoplay: true,
                    //settings: "unslick"
                    // vertical: false,
                    // verticalSwiping: false,
                    // swipeToSlide: false,
                    // slidesToShow: 2,
                    // variableWidth: true,
                    //centerMode: true,
                }
            }
        ]
    };

    useEffect(() => {
        const element = document.getElementById("overviewSection");
        let pos = element.offsetTop - (window.innerHeight/2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);
    
    const onWheelSlider = debounce((e, ref) => {
        //console.log(e);

        if (!OfferingsliderRef?.current) return;

        if (e.deltaY > 0) {
            OfferingsliderRef.current.slickNext();
            //console.log(e);
        } else if (e.deltaY < 0) {
            OfferingsliderRef.current.slickPrev();
            //console.log(ref);
        }
    }, 20);
    // function pageFadeIn() {
    //     const tl = gsap.timeline();
    //     tl.from('body', { opacity: 0, ease: 'linear', duration: 0.8 });
    //     return tl;
    // }

    // function heroAnimation() {
    //     const tl = gsap.timeline(
    //         {
    //             defaults: { opacity: 0, ease: 'ease-in', duration: 0.5 },
    //         }

    //     );
    //     tl.from('.tagline', { x: -20 })
    //     tl.from('.banner-para', { y: -20 })
    //     tl.from('.white-wrapper', { x: 150 });
    //     return tl;
    // }


    //useEffect(() => {
    // gsap.to("#circleSliderWrap", { 
    //     scrollTrigger: {
    //         trigger: "#circleSliderWrap",
    //         toggleActions: "restart none none none",
    //         pin: true,
    //         start: "top",
    //         onEnter: () => {setStopBodyScroll(true);},
    //         onLeave: () => {setStopBodyScroll(false);}
    //     },
    //     y: 0 
    // });
    //}, [stopBodyScroll]);

    // useEffect(() => {

    //const loadTl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
    //loadTl.add(pageFadeIn());
    // loadTl.add(headerLoadAnimation(), '<1');
    //loadTl.add(heroAnimation(), '<1.5');

    //}, []);
    // useEffect(() => {
    //     const element = ref.current;
    //     gsap.from(
    //         element.querySelector("#gsap-logo"),
    //         {
    //             y: -40,
    //             duration: 2,
    //             ease: "none",
    //             scrollTrigger: {
    //                 trigger: element.querySelector(".text-wrap"),
    //                 start: "bottom center",
    //                 end: "bottom top",
    //                 scrub: true
    //             }
    //         }
    //     );
    // }, []);

    // Offering Animation effects 
    function animateFrom(elem, direction, index) {
        //console.log(index, "index")
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        // elem.style.transform = "translate(" + x + "px, " + y + "px)";
        // elem.style.backgroundPosition = x + "px, " + y + "px";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });

    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem, null, index) },
                onEnterBack: function () { animateFrom(elem, -1, index) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
        gsap.utils
            .toArray(".card img")
            .forEach((section, i) => {
                const heightDiff =
                    section.offsetHeight - section.parentElement.offsetHeight;

                gsap.fromTo(
                    section,
                    {
                        y: section.parentElement.offsetHeight - 300
                    },
                    {
                        y: -150,
                        ease: "none",
                        scrollTrigger: {
                            trigger: section.parentElement,
                            scrub: true
                        }
                    }
                );
            });
    }, [])

    const nextSlide = () => {
        sliderRef.current.slickNext();
    }
    const prevSlide = () => {
        sliderRef.current.slickPrev();
    }
    const scrollDownHandler = () => {
        if (stopBodyScroll) {
            setScrollDown(true);
            setTimeout(() => {
                setScrollDown(false);
            }, 1000);
            circleSliderRef.current.slickPrev();
            circleSliderContentRef.current.slickPrev();
        }
    }

    const scrollUpHandler = () => {
        if (stopBodyScroll) {
            setScrollUp(true);
            setTimeout(() => {
                setScrollUp(false);
            }, 1000);
            circleSliderRef.current.slickNext();
            circleSliderContentRef.current.slickNext();
        }

    }

    let tabSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        center: true,
        autoplay: true,
        autoplaySpeed: 5000
    }

    useEffect(() => {
        // const panelsContainer = document.querySelector(".animateOuter");
        // const screenHeight = window.outerHeight;

        // let tl = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: ".animateOuter",
        //         toggleActions: "restart complete reverse resume",
        //         start: "top top",
        //         markers: false,
        //         scrub: 1,
        //         pin: true,
        //         onLeave: () => console.log("END")
        //         // end: () => "+=" + (panelsContainer.offsetHeight - window.innerHeight + 70),
        //     }
        // });

        // tl.from(".p-one", {
        //     autoAlpha: 1,
        //     y: 0,
        //     duration: 2,
        // });
        // tl.from(".p-one", {
        //     autoAlpha: 1,
        //     y: 0,
        // });
        // tl.to(".p-one", {
        //     autoAlpha: 0,
        //     y: -screenHeight,
        //     delay: .5,
        //     duration: 2,
        // });
        // tl.from(".p-two", {
        //     autoAlpha: 1,
        //     y: screenHeight,
        //     duration: 2,
        // });
        // tl.from(".p-two", {
        //     autoAlpha: 1,
        // });
        // tl.to(".p-two", {
        //     autoAlpha: 1,
        //     y: -screenHeight,
        // });
        // tl.from(".p-three", {
        //     autoAlpha: 1,
        //     y: screenHeight,
        //     duration: 2,
        // });
        // tl.from(".p-three", {
        //     autoAlpha: 1,
        //     // y: screenHeight,
        // });
        // tl.to(".p-three", {
        //     autoAlpha: 1,
        //     y: -screenHeight,
        //     // delay: 1,
        // });
        // tl.from(".p-four", {
        //     autoAlpha: 1,
        //     y: screenHeight,
        //     duration: 2,
        // });
        // tl.from(".p-four", {
        //     autoAlpha: 1,
        //     // y: screenHeight,
        // });
        // tl.to(".p-four", {
        //     autoAlpha: 1,
        //     y: 0,
        //     // delay: 1,
        // });


        // let tl2 = gsap.timeline({
        //     scrollTrigger: {
        //         trigger: ".animateOuter",
        //         toggleActions: "restart complete reverse resume",
        //         start: "top top",
        //         markers: false,
        //         scrub: true,
        //         pin: true,
        //         duration: 2
        //     }
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 1,
        //     x: 0
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 1
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 0, duration: 1,
        //     x: 10
        // });
        // tl2.from(".img-two", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.to(".img-two", {
        //     autoAlpha: 1,
        // });

        // tl2.to(".img-two", {
        //     autoAlpha: 0,
        //     x: 10
        // });

        // tl2.from(".img-three", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.to(".img-three", {
        //     autoAlpha: 1,
        // });

        // tl2.to(".img-three", {
        //     autoAlpha: 0,
        //     x: 10
        // });

        // tl2.from(".img-four", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.to(".img-four", {
        //     autoAlpha: 1,
        // });

        // tl.set(
        //     ".img-three",
        //     {
        //         autoAlpha: 0
        //     },
        //     "<"
        // );
        // tl2.to(".img-one", {
        //     autoAlpha: 1,
        //     scale: 1,
        //     duration: 1
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 0,
        //     scale: 0.5,
        // });
        // tl2.to(".img-one", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.from(".img-two", {
        //     autoAlpha: 0,
        //     x: 0
        // });
        // tl2.to(".img-two", {
        //     autoAlpha: 1,
        // });

        // tl2.to(".img-two", {
        //     autoAlpha: 0,
        //     x: 10
        // });
        // tl2.from(".img-three", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.to(".img-three", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.from(".img-four", {
        //     autoAlpha: 0,duration: 1,
        //     x: 10
        // });
        // tl2.to(".img-four", {
        //     autoAlpha: 1
        // });
    }, [])
    return <div className='wrapper'>
        <div className='service-wrapper home-wrapper'>
            <div className="home-page" ref={ref}>
                <div className="section-1 first">
                    <div className="product-banner">
                        <div className="banner-section">
                            <div className="container banner-container">
                                <div className="left-content first-paragraph">
                                    <h5 className="tagline">Product Engineering</h5>
                                    <h1 className="tagline"><span>We built digital </span><span>products that inspire</span><span>the
                                        future </span></h1>
                                    <p className="banner-para"><span>It is about building,
                                        transforming,
                                        sustaining the</span><span>Products (Software, Digital) with speed addressing
                                        </span><span> the real business problems. </span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomLink to="#" className="white-wrapper" renderSvg={false}>
                        <h5>Featured Whitepaper </h5>
                        <h4><span>A tool-first,
                            automated approach to scaling your </span><span>DevSecOps organization{" "}<RightArrow /></span>
                        </h4>
                    </CustomLink>
                </div>
                <div className={scroll ? "overview-section pd-top animate" : "overview-section pd-top"} id="overviewSection">
                    <div className="container text-wrap">
                        <h5>Overview</h5>
                        <div className="overview-para-1">
                            <p>Today every software is being viewed and thought of as a Product. Every new
                                Digital application is also thought of as a product hence Product Engineering is
                                becoming the foundation to the Digital world. <span>Innovation is the key to design
                                    and build the products. Adoption of cloud,
                                    microservices,
                                    API-led connectivity,
                                    containerization and DevSecOps have transformed the way software products are
                                    built.</span></p>
                        </div>
                        <div className="overview-para-2">
                            <p>Digialpha has been a trusted software product engineering services partner for
                                over 10 years for many organisations such as Ford,
                                MSX International,
                                CCR Solutions. Our specially designed services in this space address many
                                problem areas and are proven to benefit the customers.</p>
                        </div>
                        <div className="float-clear"></div>
                    </div>
                </div>
                <div className='animateOuter'>
                    <div className='scrollAnimate offering-section'>
                        <div className="containerAnimate">
                            <div className="text offering-content ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone" onWheel={onWheelSlider}>
                                <Slider ref={OfferingsliderRef} {...Offeringslider}>
                                    <div className='slider-item '>
                                        <div className="p-one row ">
                                            <div className="content-1 col">
                                                <h5>Our offerings </h5>
                                                <h1><span>Product and </span><span>Platform Strategy 1</span></h1>
                                                <p><span>Using modern day design thinking approach and</span>
                                                    <span>frameworks,
                                                        integrated with devops toolchain and with a </span><span>wide variety
                                                            of technology choices,
                                                            Product Engineering is</span><span> being taken to the next level of
                                                                evolution delivering Digital</span><span>products with great
                                                                    transparency.</span>
                                                </p>
                                                <div className="offering-li">
                                                    <ul>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Design Thinking</h4>
                                                        </li>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Technology Consulting</h4>
                                                        </li>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Digital Innovation Labs</h4>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                        </li>
                                                    </ul>
                                                    <div className="float-clear"></div>
                                                </div>
                                                <div className="progress-bar"><CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink></div>
                                                <div className="sub-title">
                                                    <h1><span>Product and</span>
                                                        <span>Platform Engineering</span>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="laptop offering-img col">
                                                <div className='img-one '><div className='img-1 '></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='slider-item '>
                                        <div className="p-two row ">
                                            <div className="content-1 col">
                                                <h5>Our offerings </h5>
                                                <h1><span>Product and </span><span>Platform Strategy 2</span></h1>
                                                <p><span>Using modern day design thinking approach and</span>
                                                    <span>frameworks,
                                                        integrated with devops toolchain and with a </span><span>wide variety
                                                            of technology choices,
                                                            Product Engineering is</span><span> being taken to the next level of
                                                                evolution delivering Digital</span><span>products with great
                                                                    transparency.</span>
                                                </p>
                                                <div className="offering-li">
                                                    <ul>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Design Thinking</h4>
                                                        </li>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Technology Consulting</h4>
                                                        </li>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Digital Innovation Labs</h4>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                        </li>
                                                    </ul>
                                                    <div className="float-clear"></div>
                                                </div>
                                                <div className="progress-bar"><CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink></div>
                                                <div className="sub-title">
                                                    <h1><span>Product and</span>
                                                        <span>Platform Engineering</span>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="laptop offering-img col">
                                                <div className='img-two '><div className='img-1'></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='slider-item '>
                                        <div className="p-three row ">
                                            <div className="content-1 col">
                                                <h5>Our offerings </h5>
                                                <h1><span>Product and </span><span>Platform Strategy 3</span></h1>
                                                <p><span>Using modern day design thinking approach and</span>
                                                    <span>frameworks,
                                                        integrated with devops toolchain and with a </span><span>wide variety
                                                            of technology choices,
                                                            Product Engineering is</span><span> being taken to the next level of
                                                                evolution delivering Digital</span><span>products with great
                                                                    transparency.</span>
                                                </p>
                                                <div className="offering-li">
                                                    <ul>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Design Thinking</h4>
                                                        </li>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Technology Consulting</h4>
                                                        </li>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Digital Innovation Labs</h4>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><span className="check-icon"></span>
                                                            <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                        </li>
                                                    </ul>
                                                    <div className="float-clear"></div>
                                                </div>
                                                <div className="progress-bar"><CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink></div>
                                                <div className="sub-title">
                                                    <h1><span>Product and</span>
                                                        <span>Platform Engineering</span>
                                                    </h1>
                                                </div>
                                            </div>
                                            <div className="laptop offering-img col">
                                                <div className='img-three '><div className='img-1'></div></div>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                                {/* <div className="p-two ipsGrid_span5 gs_reveal">
                                    <div className="content-1 gs_reveal">
                                        <h5>Our offerings </h5>
                                        <h1><span>Product and </span><span>Platform Strategy</span></h1>
                                        <p><span>Using modern day design thinking approach and</span>
                                            <span>frameworks,
                                                integrated with devops toolchain and with a </span><span>wide variety
                                                    of technology choices,
                                                    Product Engineering </span><span>is being taken to the next level of
                                                        evolution delivering Digital</span><span>products with great
                                                            transparency.</span>
                                        </p>
                                        <div className="offering-li gs_reveal">
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Design Thinking</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Technology Consulting</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Digital Innovation Labs</h4>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                </li>
                                            </ul>
                                            <div className="float-clear"></div>
                                        </div>
                                        <div className="progress-bar gs_reveal"><CustomLink to="#" renderSvg={true}>
                                            Learn More
                                        </CustomLink></div>
                                        <div className="sub-title gs_reveal">
                                            <h1><span>Product and</span>
                                                <span>Platform Engineering</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-three ipsGrid_span5 gs_reveal">
                                    <div className="content-1 gs_reveal">
                                        <h5>Our offerings </h5>
                                        <h1><span>Product and </span><span>Platform Strategy</span></h1>
                                        <p><span>Using modern day design thinking approach and</span>
                                            <span>frameworks,
                                                integrated with devops toolchain and with a </span><span>wide variety
                                                    of technology choices,
                                                    Product Engineering </span><span>is being taken to the next level of
                                                        evolution delivering Digital</span><span>products with great
                                                            transparency.</span>
                                        </p>
                                        <div className="offering-li gs_reveal">
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Design Thinking</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Technology Consulting</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Digital Innovation Labs</h4>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                </li>
                                            </ul>
                                            <div className="float-clear"></div>
                                        </div>
                                        <div className="progress-bar gs_reveal"><CustomLink to="#" renderSvg={true}>
                                            Learn More
                                        </CustomLink></div>
                                        <div className="sub-title gs_reveal">
                                            <h1><span>Product and</span>
                                                <span>Platform Engineering</span>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-four ipsGrid_span5 gs_reveal" >
                                    <div className="content-1 gs_reveal">
                                        <h5>Our offerings </h5>
                                        <h1><span>Product and </span><span>Platform Strategy</span></h1>
                                        <p><span>Using modern day design thinking approach and</span>
                                            <span>frameworks,
                                                integrated with devops toolchain and with a </span><span>wide variety
                                                    of technology choices,
                                                    Product Engineering </span><span>is being taken to the next level of
                                                        evolution delivering Digital</span><span>products with great
                                                            transparency.</span>
                                        </p>
                                        <div className="offering-li gs_reveal">
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Design Thinking</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Technology Consulting</h4>
                                                </li>
                                                <li><span className="check-icon"></span>
                                                    <h4>Digital Innovation Labs</h4>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li><span className="check-icon"></span>
                                                    <h4>Agile / Devops / DevSecOps Transformation</h4>
                                                </li>
                                            </ul>
                                            <div className="float-clear"></div>
                                        </div>
                                        <div className="progress-bar gs_reveal"><CustomLink to="#" renderSvg={true}>
                                            Learn More
                                        </CustomLink></div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="laptop offering-img">
                                <div className='img-one '><div className='img-1 gs_reveal'></div></div>
                                <div className='img-two'><div className='img-1 gs_reveal'></div></div>
                                <div className='img-three'><div className='img-1 gs_reveal'></div></div>
                                <div className='img-four'><div className='img-1 gs_reveal'></div></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="success-story-section">
                    <div className="container pd-top">
                        <h5>Client Success Stories </h5>
                        <h1><span>Our Work Drives</span><span>Innovation Forward</span></h1>
                    </div>
                    <div className="tab-menu" id="tabs">
                        <div className='container' >
                            <Slider
                                asNavFor={tabThumbRef}
                                ref={(slider2) => setTabThumbRef(slider2)}
                                beforeChange={(e) => setindexInit(e + 1)}
                                arrows={true}
                                slidesToShow={4}
                                focusOnSelect={true}
                                autoplay={true}
                                speed={500}
                                slidesToScroll={1}
                                autoplaySpeed={5000}
                                pauseOnHover={false}
                            >
                                {images.map((ele, index) => {
                                    return <div className="imagesGroupWrap" key={index}>
                                        <img src={ele} />
                                    </div>
                                })}

                            </Slider>

                        </div>
                    </div>
                    <div className="sotry-tabs tab-container">

                        <div className="tab-content">
                            <Slider
                                className='tabSlider'
                                asNavFor={tabSliderRef}
                                {...tabSliderSettings}
                                ref={(tabSliderRef) => {
                                    setTabThumbRef(tabSliderRef);
                                }}
                                pauseOnHover={false}
                            // autoplay={true}
                            // speed={500}
                            // slidesToScroll={1}
                            // autoplaySpeed={500}
                            >
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                                {/* <button className="hoverMebottom button">Read
												the full story.</button> */}
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar <button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow"><CustomLink to="#" renderSvg={true}>
                                                Read the full story
                                            </CustomLink></div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                            </Slider>
                        </div>


                    </div>
                </div>
                <WhyBetaCraft />

                <div className="ecosystem-section">
                    <div className="row container ">
                        <div className="col-1 ecosystem-content">
                            <h5>Partner Ecosystem</h5>
                            <h1>We are a partnership driven company
                            </h1>
                            <p>We form partnerships and strategic alliances with world-class
                                organisations to expand our service offerings and deliver
                                comprehensive solutions to clients. Strategic alliances with the
                                world’s leading companies enable us to provide complete
                                solutions to your business and IT challenges. </p>
                            <div className="progress-bar mobileHide">
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                                {/* <button className="hoverMebottom button"></button> */}
                            </div>
                        </div>
                        <div className="col-2 ecosystem-img">
                            <div className="marque-section ">
                                <div className="area">
                                    <ul className="circles">
                                        <li className="img-4"><img src={salesForceIcon} /></li>
                                        <li className="img-2"><img src={imbIcon} /></li>
                                        <li className="img-3"><img src={awsIcon} /></li>
                                        <li className="img-1"><img src={windowsIcon} /></li>
                                        <li className="img-5"><img src={redHatIcon} /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="progress-bar mobileShow">
                                {/* <button className="hoverMebottom button">Discover More</button> */}
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
                <Blog data={blogData} className="gray-section" />
                <GetInTouch />
                {/*  <div className="insight-scetion">
                    <div className="container">
                        <div className="heading pd-top">
                            <div className="heading-text">
                                <h5>Latest Thinkings </h5>
                                <h1>News and Insights </h1>
                            </div>
                            <div className="slider-btn">
                                <button onClick={prevSlide} className="pre-btn slide-arrow prev-arrow icon-arrow-left"></button>
                                <button onClick={nextSlide} className="next-btn slide-arrow next-arrow icon-arrow-right"></button>
                            </div>
                        </div>
                    </div>
                </div>
            <Blog /> */}
            </div>
        </div>
    </div>
}

export default Homepage;