import React, { useEffect, useRef, useState } from "react";
import BannerImage from "../../assets/images/services-detail/services-listing.png";
import BannerContent from "../../components/BannerContent";
import ServiceCard from "../../components/ServiceCard";
import WhyBetaCraft from "../../components/Common/WhyBetaCraft";
import PartnerEchoSystem from "../../components/Common/PartnerEchoSystem";
import Blog from "../../components/Blog";
import GetInTouch from "../../components/GetInTouch";
// import "../../assets/css/common.scss";
import "../../assets/css/pages/service-detail.scss";
import {blogData} from "../landing/data";
const ServiceDetailPage = () => {
    return <div className="wrapper">
        <div className="page-service-detail">
            <BannerContent BackgroundImages={BannerImage} title={'Digialpha Services'} subTitle={'Elevating Brands through innovation in Digital Transformation.'}>
                <div className="content-wrap">
                    <div className="row">
                        <div className="col ">
                            <div className="content">
                                <p>At the heart of everything we do is this idea of making business better. What drives us isn’t selling our clients a solution, it’s creating them something that is going to fundamentally improve their business.
                                </p>
                            </div>
                        </div>
                        <div className="col right-content-col">
                            <div className="content">
                                <p>Today every software is being viewed thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </BannerContent>
            <ServiceCard/>
            <WhyBetaCraft />
            <PartnerEchoSystem />
            <Blog  data={blogData} className="gray-section"/>
            <GetInTouch />
        </div>
    </div>
};

export default ServiceDetailPage;
