import React, { useEffect, useState } from "react";
import CustomLink from "../components/Common/CustomLink";
import GartnerLogo from "../assets/images/client-logos/gartner-logo.png";
import GreatPlaceImg from "../assets/images/image/great-place.png";
const AboutSection = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const element = document.getElementById("aboutSectoin");
    let pos = element.offsetTop - (window.innerHeight/2);
    window.addEventListener("scroll", () => {
        if (!scroll && window.scrollY > pos) {
            setScroll(true)
        }
    });
    return () => {
        window.removeEventListener('scroll', () => {
            setScroll(false)
        })
    }
  }, []);
  return (
    <div id="aboutSectoin" className={scroll ? "about-section animate" : "about-section"}>
      <div className="column-wrapper">
        <div className="container">
          <div className="row no-gutters">
            <div className="col left-content left-animate">
              <div className="section-title">
                <h2>About Digialpha</h2>
                <h3>
                  <span>Accelerating Global</span>{" "}
                  <span>Brands — Years ahead.</span>
                </h3>
              </div>
              <div className="content-wrap">
                <p>
                  Founded in 2013, Digialpha is a leading global technology
                  consultancy that integrates strategy, design and software
                  engineering to enable enterprises and technology disruptors
                  across the globe to thrive as modern digital businesses.
                </p>
                <CustomLink to="/company-overview" renderSvg={true} className="more-info-arrow desktop-info-arrow">
                  <span>More about Digialpha</span>
                </CustomLink>
              </div>
            </div>

            <div className="col greate-place right-animate">
              <div className="row">
                <div className="col img-1 left-content">
                  <img src={GartnerLogo} alt="Gatrner"></img>

                  <p>
                    <span>Recognized by Gartner as a delivery</span>{" "}
                    <span>capable software product</span>{" "}
                    <span>development services provider</span>{" "}
                    <span>from Asia Pacific</span>
                  </p>
                </div>
                <div className="col img-2 right-content">
                  <img src={GreatPlaceImg} alt="great-place"></img>
                </div>
              </div>
              <div className="row">
                <div className="col counter-col left-content">
                  <h4 className="number">
                    78<span className="sub-text">NPS</span>
                  </h4>
                  <p>
                    <span>A Net Promoter Score® that</span>
                    <span>makes us world-class</span>
                  </p>
                </div>
                <div className="col counter-col right-content">
                  <h4 className="number">
                    96<span className="sub-text">%</span>
                  </h4>
                  <p>
                    <span>of employees say we are </span>
                    <span>a great place to work</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <CustomLink to="/company-overview" renderSvg={true} className="more-info-arrow mobile-info-arrow">
            <span>More about Digialpha</span>
          </CustomLink>
        </div>
      </div>
    </div>
  );
};
export default AboutSection;
