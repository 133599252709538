import { commonConstants } from "../Constants/commonConstants";
import {commonApiService} from "../../api/commonService";

export const commonActions = {
  showLoader,
  hideLoader,
  getNotifiedAction,
  resetGetNotified
};

function showLoader() {
  return (dispatch) => {
    dispatch(request(commonConstants.SHOW_LOADER));
  };
  function request() {
    return { type: commonConstants.SHOW_LOADER };
  }
}
function hideLoader() {
  return (dispatch) => {
    dispatch(request(commonConstants.HIDE_LOADER));
  };
}

function resetGetNotified() {
  return (dispatch) => {
    dispatch(request(commonConstants.GET_NOTIFIED_REQUEST));
  }
}
function getNotifiedAction(email) {
  return (dispatch) => {
    dispatch(request(commonConstants.SHOW_LOADER));
    dispatch(request(commonConstants.GET_NOTIFIED_REQUEST));
    commonApiService.getNotifiedService(email).then(
      (response) => {
        dispatch(request(commonConstants.HIDE_LOADER));
        dispatch(success(commonConstants.GET_NOTIFIED_RESULT, response));
      },
      (error) => {
        dispatch(request(commonConstants.HIDE_LOADER));
        dispatch(failure(commonConstants.GET_NOTIFIED_FAILURE, error));
      }
    );
  };
}

function request(action, payload) {
  return { type: action, payload };
}

function success(action, response) {
  return { type: action, response };
}

function failure(action, error) {
  return { type: action, error };
}
