import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
const BannerContent = ({
  BackgroundImages,
  title,
  subTitle,
  children,
  text,
}) => {
  return (
    <div className="banner-main-wrap">
      <div className="banner-content-wrap" style={{ backgroundImage: `url(${BackgroundImages})` }}>
        {/* <div className="banner-content-wrap"> */}
      </div>
      <div className="banner-content banner-bg">
          <div className="container ">
            <div className="section-title">
              <h1>{title}</h1>
              <h3>{subTitle}</h3>
            </div>
            {children}
          </div>
        </div>
    </div>
  );
};

export default BannerContent;
