import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BannerContent from "../../components/BannerContent";
import GetInTouch from "../../components/GetInTouch";
import CheckboxMenu from "../../components/Common/MultipleSelect";
import ServiceCardNew from "../../components/ServiceCardNew";
import { VscClose } from "react-icons/vsc";

import "../../assets/JS/index.js";
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import "../../assets/css/menu.scss";
import { ReactComponent as Instagram } from "../../assets/images/icon/instagram.svg";
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as RightArrow2 } from "../../assets/images/icon/right-arrow2.svg";
import { ReactComponent as LeftArrow2 } from "../../assets/images/icon/left-arrow2.svg";
import OfcLogo from "../../assets/images/case-study/official-nft-logo.png";
import VtravelLogo from "../../assets/images/case-study/vtravel-logo.png";
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/case-study/case-study-detail-banner.png";
import BannerImage2 from "../../assets/images/case-study/case-study-detail-banner1.png";

import CardImage from "../../assets/images/case-study/card-image.png";

import "../../assets/css/pages/case-study.scss";
const CaseStudyDetail = () => {
    const [PopupContent, setOpenPopup] = useState(false);
    const [SuccessPopup, setSuccessPopup] = useState(false);

    return (
        <div className="wrapper">
            <div className="page-case-study page-case-study-detail">
                <BannerContent BackgroundImages={BannerImage2} title={'Blockchain'} subTitle={'Discover, Collect & Sell extraordinary NFTs'}>
                    <div className="content-wrap">
                        <div className="learn-more-wrap">
                            <div className="client-logo" >
                                <img src={OfcLogo} alt="logo" />
                            </div>
                        </div>
                    </div>
                </BannerContent>
                <div className="banner-content-img-wrap">
                    <div className="container">
                        <div className="banner-content-img" style={{ backgroundImage: `url(${BannerImage})` }}>

                        </div>
                    </div>
                </div>
                <div className="case-study-cards-wrap">
                    <div className="container">
                        <div className="case-study-wrapper">
                            <div className="case-study-content-wrap">
                                <div className="case-study-content">
                                    <div className="row">
                                        <div className="col left-content">
                                            <div className="content">
                                                <p>Our client was endeavoring to reduce costs in its client center operations and improve customer service. Due to complex legacy systems, improper documentation, and broken processes, the client was struggling with increased costs and dissatisfactory service. We embarked with our client on an automation journey, from POC to hyper-automation, to help them solve their problems.
                                                </p>
                                                <div className="content-link">
                                                    <a href="#">Read the case study to know more!</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col right-content">
                                            <div className="content-wrap">
                                                <span onClick={() => setOpenPopup(true)}>
                                                    <CustomLink to="" renderSvg={false} className="typeButton">
                                                        Download Case Study<RightArrow className="right-arrow" />
                                                    </CustomLink>
                                                </span>
                                                <div className="tag-info">
                                                    <h4 className="heading-title">
                                                        Tags:
                                                    </h4>
                                                    <p>Data Engineering & Analytics Banking and financial services</p>
                                                </div>
                                                <div className="social-info">
                                                    <h4 className="heading-title">
                                                        Share:
                                                    </h4>
                                                    <ul>
                                                        <li> <Link target="_blank" to="/"><AiFillFacebook /></Link></li>
                                                        <li> <Link target="_blank" to="/"><Instagram /> </Link></li>
                                                        <li> <Link target="_blank" to="/"><AiFillLinkedin /></Link></li>
                                                        <li> <Link target="_blank" to="/"><AiOutlineTwitter /></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 class="heading-title">Also read:</h4>
                                <ServiceCardNew />
                            </div>
                        </div>
                    </div>
                </div>
                <GetInTouch />
            </div >

            {/* Popup start */}
            {PopupContent && <div className="popup contact-form-popup">
                <div className="popup-content">
                    <div className="popup-title">
                        <button type="button" className="btn close" onClick={() => setOpenPopup(false)}>
                            <VscClose />
                        </button>
                    </div>
                    <div className="content-box">
                        <h2>Please fill out the form below</h2>
                        <form>
                            <div className="form-group">
                                <div className="form-group-row row no-gutters">
                                    <div className="col number-col">
                                        <h6>01</h6>
                                    </div>
                                    <div className="col input-box">
                                        <h5>What’s your name?</h5>
                                        <input type="text" placeholder="John Doe *" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-group-row row no-gutters">
                                    <div className="col number-col">
                                        <h6>02</h6>
                                    </div>
                                    <div className="col input-box">
                                        <h5>What's your work email?</h5>
                                        <input type="text" placeholder="john@doe.com *" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-group-row row no-gutters">
                                    <div className="col number-col">
                                        <h6>03</h6>
                                    </div>
                                    <div className="col input-box">
                                        <h5>From which country you are?</h5>
                                        <input type="text" placeholder="Select Country*" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group checkbox-group">
                                <div className="form-group-row row no-gutters">
                                    <div class="custom-checkbox">
                                        <input type="checkbox" id="checkbox" />
                                        <span class="checkmark"></span>
                                        <label htmlFor="checkbox">I consent to the above details being used to keep me informed about Betacraft products, services and events.
                                        </label>
                                    </div>
                                    <p>
                                        <small>This form collects your name and email so that we can add you to our newsletter list for product updates and useful marketing tips and tricks. You can unsubscribe at any time. Check out our privacy policy and cookie policy</small>
                                    </p>
                                </div>
                            </div>
                            {/* <button type="submit" className="submit-btn">Submit<RightArrow className="right-arrow" /></button> */}
                            <button type="button" onClick={() => setSuccessPopup(true)} className="submit-btn">Submit<RightArrow className="right-arrow" /></button>
                        </form>
                    </div>
                </div>
            </div>}
            {SuccessPopup && <div className="popup form-success-popup">
                <div className="popup-content">
                    <div className="popup-title">
                        <button type="button" className="btn close" onClick={() => setSuccessPopup(false)}>
                            <VscClose />
                        </button>
                    </div>
                    <div className="content-box">
                        <h2>Thank you
                            <span>Sachin, for your interest!</span></h2>
                            <h3> You can download the asset by clicking the link below</h3>
                            <button type="button" className="btn-orange">Download<RightArrow className="right-arrow" /></button>
                    </div>
                </div>
            </div>}
        </div >
    );
}

export default CaseStudyDetail;
