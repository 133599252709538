import React, { Component } from "react";
import BannerContent from "./BannerContent";
import { ReactComponent as RightArrow } from "../assets/images/icon/gray-arrow-right.svg";
import BannerImage from "../assets/images/contact-us/banner.png";

const ContactForm = ({ subtitle1 }) => {
  return (
    <div className="contact-form-wrapper">
      <BannerContent
        BackgroundImages={BannerImage}
        title={"Contact us"}
        subTitle={
          "At Digialpha, we believe that those who challenge the status quo today will do remarkable things tomorrow."
        }
      >
        <div className="form-wrap">
          <p>
            {" "}
            If you're as passionate about the possibilities as we are, please
            contact us.
          </p>

          <div className="form-field">
            <div className="col-7">
              <form>
                <div className="form-field-wrapper">
                  <div className="field-content">
                    <div className="label-wrap">
                      <span>01</span>
                      <label className="label-text" for="fname">
                        What’s your name?
                      </label>
                    </div>
                    <input
                      className="form-input"
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="John Doe*"
                    />
                  </div>

                  <div className="field-content">
                    <div className="label-wrap">
                      <span>02</span>
                      <label className="label-text" for="fname">
                        What's your work email?
                      </label>
                    </div>
                    <input
                      className="form-input"
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="john@doe.com *
"
                    />
                  </div>

                  <div className="field-content">
                    <div className="label-wrap">
                      <span>03</span>
                      <label className="label-text" for="fname">
                        What's the name of your organization?
                      </label>
                    </div>
                    <input
                      autocomplete="off"
                      className="form-input"
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="John & Doe ®"
                    />
                  </div>

                  <div className="field-content">
                    <div className="label-wrap">
                      <span>04</span>
                      <label className="label-text" for="fname">
                        From which country you are?
                      </label>
                    </div>
                    <input
                      autocomplete="off"
                      className="form-input"
                      type="dropdown"
                      id="fname"
                      name="fname"
                      placeholder="Select Country*"
                    />
                  </div>
                  <div className="field-content">
                    <div className="label-wrap">
                      <span>05</span>
                      <label className="label-text" for="fname">
                        What services are you looking for?
                      </label>
                    </div>
                    <input
                      autocomplete="off"
                      className="form-input"
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Web Design, Web Development ... "
                    />
                  </div>

                  <div className="field-content">
                    <div className="label-wrap">
                      <span>06</span>
                      <label className="label-text" for="fname">
                        Your message
                      </label>
                    </div>
                    <input
                      autocomplete="off"
                      className="form-input"
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Hello Digialpha, can you help me with ... *"
                    />
                  </div>
                </div>
                <div className="agree-section">
                  <div className="information-1 custom-checkbox">
                    <input type="checkbox" id="checkbox" />
                    <span className="checkmark"></span>
                    <label htmlFor="checkbox">
                      I consent to the above details being used to keep me
                      informed about Digialpha products, services and events.
                    </label>
                  </div>
                  <div className="information-2">
                    <p>
                      This form collects your name and email so that we can add
                      you to our newsletter list for product updates and useful
                      marketing tips and tricks. You can unsubscribe at any
                      time. Check out our <a href="#">privacy policy</a> and <a href="#"> cookie policy</a>
                    </p>
                  </div>
                  <div className="submit-section">
                    <span className="border-line"> </span>
                    <button className="submit-btn" type="submit">
                      <p>
                        Send it! <RightArrow className="right-arrow" />
                      </p>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-3 mobile-hide">
              <div className="email-contact">
                <p>
                  Feel free to reach out if you want to collaborate with us, or
                  simply have a chat.
                </p>
                <a href="mailto:  contact@betacrft.com">contact@betacrft.com</a>
              </div>

              <div className="email-contact">
                <p>
                  Feel free to reach out if you want to collaborate with us, or
                  simply have a chat.
                </p>
                <a href="mailto: info@betacrft.com"> info@betacrft.com</a>
              </div>

              <div className="email-contact">
                <p>
                  Feel free to reach out if you want to collaborate with us, or
                  simply have a chat.
                </p>
                <a href="mailto: careers@betacrft.com">careers@betacrft.com</a>
              </div>
            </div>
          </div>
        </div>
      </BannerContent>
      <div className="location-info">
        <div className="container">
          <div className="email-contact">
            <p>
              Feel free to reach out if you want to collaborate with us, or
              simply have a chat.
            </p>
            <a href="mailto:  contact@betacrft.com">contact@betacrft.com</a>
          </div>

          <div className="email-contact">
            <p>
              Feel free to reach out if you want to collaborate with us, or
              simply have a chat.
            </p>
            <a href="mailto: info@betacrft.com"> info@betacrft.com</a>
          </div>

          <div className="email-contact">
            <p>
              Feel free to reach out if you want to collaborate with us, or
              simply have a chat.
            </p>
            <a href="mailto: careers@betacrft.com">careers@betacrft.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
