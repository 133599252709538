import http from "./http";

function blogService(payload) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_BLOG}/wp-json/blogs/v1/get-latest-posts`,
      payload
    )
    .then((response) => response);
}

function featuredBlogService() {
  return http
    .get(
      `${process.env.REACT_APP_BASE_URL_BLOG}/wp-json/blogs/v1/get-featured-post`
    )
    .then((response) => response);
}

function blogDetailsService(id) {
  return http
    .get(`${process.env.REACT_APP_BASE_URL_BLOG}/wp-json/blogs/v1/detail/${id}`)
    .then((response) => response);
}

export const blogApiService = {
  blogService,
  featuredBlogService,
  blogDetailsService,
};
