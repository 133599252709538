import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/pages/comingSoon.scss";
import { ReactComponent as Instagram } from "../../assets/images/icon/instagram.svg";
import logoPath from "../../assets/images/logo/logo2.svg";
import { FaYoutube } from "react-icons/fa";
import { AiFillFacebook, AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import Countdown from 'react-countdown';
import {commonActions} from "../../Stores/Actions";
import {ColorRing} from 'react-loader-spinner';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const ComingSoon = () => {
    const dispatch = useDispatch();
    const getNotifiedData = useSelector(state => state.commonReducer.getNotifiedData);
    const loading = useSelector(state => state.commonReducer.loading);
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(null);
    const time = Date.parse("20 July 2024") - Date.parse(new Date());

    useEffect(() => {
        if(!isSubmitted && getNotifiedData){
            setIsSubmitted(true);
            setEmailError(null);
            setEmail(null);
            
            setTimeout(() => {
                setIsSubmitted(null);
                dispatch(commonActions.resetGetNotified());
            }, 30000)
        }
    }, [isSubmitted, getNotifiedData]);
    
    const handleInputChange = (e) => {
        setEmail(e.target.value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if(!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/.test(email)){
            setEmailError("Invalid Email");
            return false;
        }
        dispatch(commonActions.getNotifiedAction(email));
        return false;
    }
    return <div className="comingSoon" >
        <span className="siteLogo" >
            <img src={logoPath} />
        </span>
        
        <div className="countDownWrap" >
            <Countdown className="counter" date={Date.now() + time} />
            <div className="countDownUnits" >
                <span>days</span>
                <span>hours</span>
                <span>mins</span>
                <span>sec</span>
            </div>
        </div>
        
        <div className="middleText" >
            <h3>SITE UNDER CONSTRUCTION</h3>
            <h1>COMING SOON</h1>
        </div>

        <form onSubmit={(e)=> {handleSubmit(e)}} className="emailNotification" >
            {getNotifiedData && isSubmitted ? <>
                <h4>It's Nice To Hear You're Interested</h4>
                <p>We appreciate your interest in us. <span>We will continue to keep you in the loop </span>while all of this is going on.</p>
            </> : <><label> Get Notified!</label>
            <div className={`inputWrap ${emailError ? "error" : ""}`} >
                <input 
                    type="email" 
                    value={email}
                    disabled={loading}
                    placeholder="Please enter your email" 
                    onChange={handleInputChange}
                />  
                <button disabled={loading} className={`btn ${loading ? "disabled" : ""}`} type="submit">
                    {loading ? 
                        <ColorRing
                            visible={true}
                            height="40"
                            width="40"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                        /> : 
                        "Notify Me"}
                </button>
            </div></>}
        </form>


        <div className="footerStyle" >
            <span className="copyText">&copy; 2024 Digialpha. All rights reserved.</span>
            <div className="social-media-icon">
                <ul>
                    <li> <Link target="_blank" to="/"><AiFillFacebook /></Link></li>
                    <li> <Link target="_blank" to="/"><Instagram /> </Link></li>
                    <li> <Link target="_blank" to="/"><AiFillLinkedin /></Link></li>
                    <li> <Link target="_blank" to="/"><AiOutlineTwitter /></Link></li>
                    <li> <Link target="_blank" to="/"><FaYoutube /></Link></li>
                </ul>
            </div>
        </div>
    </div>
}

export default ComingSoon;