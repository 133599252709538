import React, { useEffect } from "react";
import { gsap } from 'gsap';
import CustomLink from "../components/Common/CustomLink";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Card1 from "../assets/images/services-detail/card/service-card.png";
import CustomerExCard from "../assets/images/services-card-img/customer-experience.jpg";
import DigitalEnggCard from  "../assets/images/services-card-img/digital-engineering-card.jpg";
import DataAnalysticsCard from "../assets/images/services-card-img/data-analytics-card.jpg";
import AppServicesCard from "../assets/images/services-card-img/application-services-card.jpg";
import MVPcard from "../assets/images/services-card-img/MVP-card.jpg";
import DigitalProductCard from "../assets/images/services-card-img/digital-product-eng.jpg";

const ServiceCard = () => {
    function animateFrom(elem, direction, index) {
        console.log(index, "index")
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        // elem.style.transform = "translate(" + x + "px, " + y + "px)";
        // elem.style.backgroundPosition = x + "px, " + y + "px";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });
        // var tl = tl = gsap.timeline();
        // if(direction){
            
        //     gsap.from(elem.firstChild.firstChild, {
        //         scrollTrigger: {
        //           scrub: true
        //         }, 
        //         y: (i, target) => (-elem.offsetTop - elem.offsetHeight * 0.75),
        //         ease: "none"
        //       });
        //         gsap.to(elem.firstChild.firstChild, {
        //             scrollTrigger: {
        //               scrub: true
        //             }, 
        //             y: (i, target) => elem.offsetTop,
        //             ease: "none"
        //           });
        // }else {
        //     gsap.from(elem.firstChild.firstChild, {
        //         scrollTrigger: {
        //           scrub: true
        //         }, 
        //         y: (i, target) => (-elem.offsetTop + elem.offsetHeight * 0.75),
        //         ease: "none"
        //       });
        //         gsap.to(elem.firstChild.firstChild, {
        //             scrollTrigger: {
        //               scrub: true
        //             }, 
        //             y: (i, target) => elem.offsetTop,
        //             ease: "none"
        //           });
        // }
        
        
       
          
        
    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem, null, index) },
                onEnterBack: function () { animateFrom(elem, -1, index) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
        gsap.utils
        .toArray(".card img")
        .forEach((section, i) => {
            const heightDiff =
            section.offsetHeight - section.parentElement.offsetHeight;

            console.log(section);
            console.log(section.parentElement);

            // gsap.to(section, {
            //   scrollTrigger: {
            //     trigger: section.parentElement,
            //     scrub: true
            //   },
            //   y: section.parentElement.offsetHeight - section.offsetHeight,
            //   ease: "none"
            // });

            gsap.fromTo(
            section,
            {
                y: section.parentElement.offsetHeight - 300
            },
            {
                y: -150,
                ease: "none",
                scrollTrigger: {
                trigger: section.parentElement,
                scrub: true
                }
            }
            );
        });
    }, [])

    return (
        <div className="section-service-card">
            <div className="container">
                <div className="cInnerContent">
                    <div className="feature-row row">
                         {/* Customer Experience section  */}
                        <div className="col col1">  
                            <div className="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
                                <div className="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft">
                                    <div className="card">
                                        <img src={CustomerExCard} />
                                    </div>
                                </div>

                                <div className="content-wrap ipsGrid_span7 ipsType_left">
                                    <h2 className="heading_large gs_reveal">Customer Experience (CX) & Design</h2>
                                    <p className="gs_reveal">Customer Experience focus is a must for the business to be successful today and hence we address it through our experienced professionals and solutions.</p>
                                    <div className="learn-more-btn">
                                        <CustomLink to="/services/customer-experience-and-design" className="gs_reveal" renderSvg={true}>
                                            <h5>Learn More </h5>
                                        </CustomLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Digitial Product section  */}
                        <div className="col col2">
                            <div className="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
                                <div className="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight">
                                    <div className="card">
                                        <img src={DigitalProductCard} />
                                    </div>
                                </div>
                                <div className="content-wrap ipsGrid_span7 ipsType_right">
                                    <h2 className="heading_large gs_reveal">Digital Product Engineering</h2>
                                    <p className="gs_reveal">Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices, Product Engineering is being taken to the next level of evolution delivering Digital products with great transparency.</p>
                                    <div className="learn-more-btn">
                                        <CustomLink to="/services/services-detail" className="gs_reveal" renderSvg={true}>
                                            <h5>Learn More </h5>
                                        </CustomLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Digital Engineering  section  */}
                        <div className="col col1">
                            <div className="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
                                <div className="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft">
                                    <div className="card">
                                        <img src={DigitalEnggCard} />
                                    </div>
                                </div>

                                <div className="content-wrap ipsGrid_span7 ipsType_left">
                                    <h2 className="heading_large gs_reveal">Digital Engineering & Services</h2>
                                    <p className="gs_reveal">Our solutions and offerings drive the Digital Journey of an organisation making them truly digital delivering the benefits.</p>
                                    <div className="learn-more-btn">
                                        <CustomLink to="/services/digital-engineering-and-services" className="gs_reveal" renderSvg={true}>
                                            <h5>Learn More </h5>
                                        </CustomLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Data and Analytics  section  */}
                        <div className="col col2">
                            <div className="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
                                <div className="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight">
                                    <div className="card">
                                        <img src={DataAnalysticsCard} />
                                    </div>
                                </div>
                                <div className="content-wrap ipsGrid_span7 ipsType_right">
                                    <h2 className="heading_large gs_reveal">Data and Analytics</h2>
                                    <p className="gs_reveal">Data and Analytics Services and solutions help build the data foundation to the analytics layer unleashing the power of data in an organisation.</p>
                                    <div className="learn-more-btn">
                                        <CustomLink to="/services/data-and-analytics" className="gs_reveal" renderSvg={true}>
                                            <h5>Learn More </h5>
                                        </CustomLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                         {/* Application Services section  */}
                        <div className="col col1">
                            <div className="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
                                <div className="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft">
                                    <div className="card">
                                        <img src={AppServicesCard} />
                                    </div>
                                </div>

                                <div className="content-wrap ipsGrid_span7 ipsType_left">
                                    <h2 className="heading_large gs_reveal">Application Services</h2>
                                    <p className="gs_reveal">Truly end to end services in the custom applications, ERP and Quality (testing) delivering “keep the lights” (BAU), 
                                    modernization, upgrade, and “Testing as a service”.</p>
                                    <div className="learn-more-btn">
                                        <CustomLink to="/services/application-services" className="gs_reveal" renderSvg={true}>
                                            <h5>Learn More </h5>
                                        </CustomLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* MVP section  */}
                        <div className="col col2">
                            <div className="feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone">
                                <div className="featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight">
                                    <div className="card">
                                        <img src={MVPcard} />
                                    </div>
                                </div>
                                <div className="content-wrap ipsGrid_span7 ipsType_right">
                                    <h2 className="heading_large gs_reveal">MVP Development</h2>
                                    <p className="gs_reveal"></p>
                                    <div className="learn-more-btn">
                                        <CustomLink to="/services" className="gs_reveal" renderSvg={true}>
                                            <h5>Learn More </h5>
                                        </CustomLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;