import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import BannerContent from "../../components/BannerContent";
import PartnerEchoSystem from "../../components/Common/PartnerEchoSystem";
import GetInTouch from "../../components/GetInTouch";
import CoreValues from "../../components/CoreValues";
import debounce from 'lodash.debounce'
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/about/banner.png";
import OurPurposeImage from "../../assets/images/about/our-purpose.png";
import ServiceSliderImage from "../../assets/images/about/service-slider/1.png";
import Linkedin from "../../assets/images/icon/linkedin.png";
import CeoImage from "../../assets/images/team/ceo.png";
import CooImage from "../../assets/images/team/coo.png";
import CtoImage from "../../assets/images/team/cto.png";
import VpbImage from "../../assets/images/team/vpb.png";
import CdImage from "../../assets/images/team/cd.png";
import CfoImage from "../../assets/images/team/cfo.png";
import DirectorImage from "../../assets/images/team/director.png";
import SvpdImage from "../../assets/images/team/svpd.png";
import AdvisorImage from "../../assets/images/team/advisor.png";
import ShiftLeftArrow from "../../assets/images/icon/shift-left-arrow.svg";
import ShiftRightArrow from "../../assets/images/icon/shift-right-arrow.svg";

import CsrImage1 from "../../assets/images/about/csr1.png";
import CsrImage2 from "../../assets/images/about/csr2.png";
import MarqueeImg1 from "../../assets/images/client-logos/zensar.png";
import MarqueeImg2 from "../../assets/images/client-logos/bigbinary.png";
import MarqueeImg3 from "../../assets/images/client-logos/ccr.png";
import MarqueeImg4 from "../../assets/images/client-logos/fixit.png";
import MarqueeImg5 from "../../assets/images/client-logos/ford-8.png";
import MarqueeImg6 from "../../assets/images/client-logos/forgeahead-main-logo.png";
import MarqueeImg7 from "../../assets/images/client-logos/Geoawseome.png";
import MarqueeImg8 from "../../assets/images/client-logos/Gimmonix.png";
import MarqueeImg9 from "../../assets/images/client-logos/InT.png";
import MarqueeImg10 from "../../assets/images/client-logos/Kyocera.png";
import MarqueeImg11 from "../../assets/images/client-logos/logo-tavisca-new.png";
import MarqueeImg12 from "../../assets/images/client-logos/midnight.png";
import MarqueeImg13 from "../../assets/images/client-logos/Msx.png";
import MarqueeImg14 from "../../assets/images/client-logos/Nomad_Logo_black.png";
import MarqueeImg15 from "../../assets/images/client-logos/officialnft.png";
import MarqueeImg16 from "../../assets/images/client-logos/precision-flight.png";
import MarqueeImg17 from "../../assets/images/client-logos/precision-hawk.png";
import MarqueeImg18 from "../../assets/images/client-logos/Real-park.png";
import MarqueeImg19 from "../../assets/images/client-logos/Royal-gulf.png";
import MarqueeImg20 from "../../assets/images/client-logos/TAconnections.png";
import MarqueeImg21 from "../../assets/images/client-logos/terraserver.png";
import MarqueeImg22 from "../../assets/images/client-logos/Tlx.png";
import MarqueeImg23 from "../../assets/images/client-logos/tripello.png";
import MarqueeImg24 from "../../assets/images/client-logos/vTravel.png";
import MarqueeImg25 from "../../assets/images/client-logos/webonoise.png";
import MarqueeImg26 from "../../assets/images/client-logos/TripStreak.png";
// import MarqueeImg27 from "../../assets/images/client-logos/Recruiter.png";

import "../../assets/css/pages/about.scss";
import { Route, Link, Routes, useLocation } from 'react-router-dom';

const AboutUs = () => {
    const divRef = useRef();
    const scollToCompanyOverviewRef = useRef(null);
    const scollToLeadershipRef = useRef(null);
    const csrsliderRef = useRef(null);
    const scollToCSRRef = useRef(null);
    const scollToOurPurposeRef = useRef(null);
    const scollToOurValuesRef = useRef(null);
    const [leftMargin, setMarginLeft] = useState(0)
    const [divHeight, setDivHeight] = useState(0)
    const location = useLocation();

    const [scroll, setScroll] = useState(false);
    const [scrollLeadership, setScrollLeadership] = useState(false);
    const [scrollCeo, setScrollCeo] = useState(false);
    
    const [scrollServicesSlider, setScrollServicesSlider] = useState(false);

    useEffect(() => {
        const element = document.getElementById("ourPurposeSection");
        let pos = element.offsetTop - (window.innerHeight/2);
        
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }        
      }, []);
    
    
      useEffect(() => {
        const element = document.getElementById("leadership-team");
        let pos = element.offsetTop - (window.innerHeight);
        
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScrollLeadership(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrollLeadership(false)
            })
        }        
      }, []);

      useEffect(() => {
        const element = document.getElementById("ceoSection");
        let pos = element.offsetTop - (window.innerHeight);
        
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScrollCeo(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrollCeo(false)
            })
        }        
      }, []);
    
    
    useEffect(() => {
        const element = document.getElementById("servicesSliderSection");
        let pos = element.offsetTop - (window.innerHeight/2);
        
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScrollServicesSlider(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrollServicesSlider(false)
            })
        }        
      }, []);

      useEffect(() => {
        setInterval(function(){
          css2Marquee1()
      }, 10000)
      css2Marquee1()
      }, []);
      const css2Marquee1 = () => {
        let ele = document.getElementById('css2Marquee1').getElementsByClassName('slider-item')[0];
        document.getElementById('css2Marquee1').append(ele)
    
        let ele2 = document.getElementById('css2Marquee2').getElementsByClassName('slider-item')[0];
        document.getElementById('css2Marquee2').append(ele2)
      }
    useEffect(() => {
        if (["/about-us"].indexOf(window.location.pathname) > -1) {
            document.querySelector("body").classList.add("page-about-main-wrapper");
            return () => {
                document.querySelector("body").classList.remove("page-about-main-wrapper");
            };
        }
        window.addEventListener('resize', function () {
            setOffetMargin()
        });

        return () => {
            window.removeEventListener('resize', setOffetMargin);
        }
    }, []);

    useEffect(() => {
        const height = document.getElementById('csr-slider-wrap-id').clientHeight;
        console.log(height);
        setDivHeight(height);

        if (window.location.href.includes("#corporate-values")) {
            scollToOurValuesRef?.current?.scrollIntoView();
        } else if (window.location.href.includes("#corporate-social-responsibility")) {
            scollToCSRRef?.current?.scrollIntoView();
        } else if (window.location.href.includes("#our-purpose")) {
            scollToOurPurposeRef?.current?.scrollIntoView();
        }
        else if (window.location.href.includes("#our-vision")) {
            scollToOurPurposeRef?.current?.scrollIntoView();
        }
        else if (window.location.href.includes("#leadership-team")) {
            scollToLeadershipRef?.current?.scrollIntoView();
        }
        else if (window.location.href.includes("company-overview")) {
            scollToCompanyOverviewRef?.current?.scrollIntoView();
        }
        else {

        }
        //console.log(":::::::",location);

    }, [location])


    const setOffetMargin = () => {
        if (divRef?.current) {
            setMarginLeft(divRef?.current.offsetLeft)
        }
    }
    useEffect(() => {
        setOffetMargin();

    }, [divRef])

    const sliderRef = useRef();
    const [slideIndex, setSlideIndex] = useState(0);
    const nextSlide = () => {
        sliderRef.current.slickNext();
    }
    const prevSlide = () => {
        sliderRef.current.slickPrev();
    }

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" disabled={slideIndex === 4} className={className} onClick={onClick}>
                <img src={ShiftRightArrow} />
            </button>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <button type="button" disabled={slideIndex === 0} className={className} onClick={onClick}><img src={ShiftLeftArrow} /></button>
        );
    }
    const settings = {
        dots: true,
        fade: false,
        autoplay: false,
        infinite: false,
        autoplaySpeed: 4000,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        centerMode: false,
        centerPadding: '0px',
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            }
        ]

    };
    const slider_first = {
        dots: false,
        fade: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        speed: 9000,
        slidesToShow: 5,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }
        ]

    };
    const slider_second = {
        dots: false,
        fade: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        speed: 9000,
        slidesToShow: 5,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false,
        rtl: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }
        ]

    };
    const slider_csr = {
        autoplay: false,
        dots: false,
        fade: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true,
        autoplaySpeed: "0",
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                    swipeToSlide: false,
                    slidesToShow: 2,
                    variableWidth: true,
                    //centerMode: true,
                }
            }
        ]
    };

    const onWheelSlider = debounce((e, ref) => {
        //console.log(e);

        if (!sliderRef?.current) return;

        if (e.deltaY > 0) {
            sliderRef.current.slickNext();
            //console.log(e);
        } else if (e.deltaY < 0) {
            sliderRef.current.slickPrev();
            //console.log(ref);
        }
    }, 20);
    return (
        <div className="wrapper">
            <div className="page-about-us" ref={scollToCompanyOverviewRef}>
                <BannerContent BackgroundImages={BannerImage} title={'About Digialpha '} subTitle={'We are a forward-thinking team of designers & developers driven by passion '}>
                    <div className="content-wrap">
                        <div className="row">
                            <div className="col ">
                                <div className="content">
                                    <p>Founded in 2013, Digialpha is a leading global technology consultancy that integrates strategy, design and software engineering to enable enterprises and technology disruptors across the globe to  thrive as modern digital businesses.</p>
                                    <p>Ongoing digital disruption is challenging enterprises to keep pace with the accelerating rate of technological change.</p>
                                </div>
                            </div>
                            <div className="col right-content-col">
                                <div className="content">
                                    <p>This is where Digialpha can help. We've been at the forefront of technology innovation as a thought leader over the past 8 years.</p>
                                    <p>We leverage our vast experience to improve our clients’ ability to respond to change; utilize data assets to unlock new sources of value; create adaptable technology platforms that move with business strategies; and rapidly design, deliver and evolve exceptional digital products and experiences at scale.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </BannerContent>
                <div ref={scollToOurValuesRef} ></div>
                <CoreValues />
                <div ref={scollToOurPurposeRef} id="ourPurposeSection" className={scroll ? "our-purpose-section animate" : "our-purpose-section"}  style={{ backgroundImage: `url(${OurPurposeImage})` }}>
                    <div className="container" ref={divRef}>
                        <div className="row">
                            <div id="#our-purpose" className="col col-6">
                                <div className="card-main">
                                    <div className="card black-bg">
                                        <div className="card-content">
                                            <h2>Our Purpose</h2>
                                            <h3>We engineer modern businesses to improve everyday life.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="#our-vision" className="col col-6">
                                <div className="card-main">
                                    <div className="card">
                                        <div className="card-content">
                                            <h2>Our Vision</h2>
                                            <h3>We engineer modern businesses to improve everyday life.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* our purpose section */}
                <div id="servicesSliderSection" className={scrollServicesSlider ? "services-slider-section animate" : "services-slider-section"} >
                    <div className="container">
                        <div className="section-title">
                            <h2>Digialpha Services</h2>
                            <p>We are passionate about uncovering the best digital innovations for forward—thinking brands looking to push boundaries and drive significant impact.</p>
                        </div>
                    </div>
                    <div className="slider-wrapper">
                        <div className="container" style={{ marginLeft: `${leftMargin}px` }}>
                            <Slider {...settings} ref={sliderRef} afterChange={(index) => {
                                setSlideIndex(index)
                            }}>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Customer Experience & Design</h3>
                                            <div className="content">
                                                <p>We’ve worked incredibly hard to build a talented, industry leading team of professionals. With a team of creative, strategist, business and development
                                                    specialists.</p>
                                                <CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={ServiceSliderImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Digital Product Engineering</h3>
                                            <div className="content">
                                                <p>We’ve worked incredibly hard to build a talented, industry leading team of professionals. With a team of creative, strategist, business and development
                                                    specialists.</p>
                                                <CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={ServiceSliderImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Digital Engineering & Services</h3>
                                            <div className="content">
                                                <p>We’ve worked incredibly hard to build a talented, industry leading team of professionals. With a team of creative, strategist, business and development
                                                    specialists.</p>
                                                <CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={ServiceSliderImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>Data & Analytics</h3>
                                            <div className="content">
                                                <p>We’ve worked incredibly hard to build a talented, industry leading team of professionals. With a team of creative, strategist, business and development
                                                    specialists.</p>
                                                <CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={ServiceSliderImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col slider-item">
                                    <div className="card">
                                        <div className="card-content">
                                            <h3>MVP BetaLab</h3>
                                            <div className="content">
                                                <p>We’ve worked incredibly hard to build a talented, industry leading team of professionals. With a team of creative, strategist, business and development
                                                    specialists.</p>
                                                <CustomLink to="#" renderSvg={true}>
                                                    Learn More
                                                </CustomLink>

                                            </div>
                                            <div className="card-image">
                                                <img src={ServiceSliderImage} alt="slider image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div ref={scollToLeadershipRef}></div>
                <div id="ceoSection" className={scrollCeo ? "ceo-section animate" : "ceo-section"} >
                    <div className="container">
                        <div className="row">
                            <div className="col img-col">
                                <div className="bg-image" style={{ backgroundImage: `url(${CeoImage})` }}>
                                    <a href="https://www.linkedin.com/in/pushkarajshelke/" target="_blank"><img src={Linkedin} alt="social icon" /> </a>
                                </div>
                            </div>
                            <div className="col right-content">
                                <div className="content">
                                    <h2>Word from our CEO</h2>
                                    <h3>“Commitment to diversity and inclusion can change not only what we’re capable of achieving at Digialpha—but also what the whole world can achieve.”</h3>
                                    <div className="signature">
                                        <h4><span>—</span> Pushkraj Shelke</h4>
                                        <h5><span></span> Founder & CEO</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="leadership-team" className={scrollLeadership ? "leadership-team-section animate" : "leadership-team-section"} >
                    <div className="container">
                        <div className="leadership-team-wrap">
                            <div className="leadership-item">
                                <div className="section-title">
                                    <h2>Leadership Team</h2>
                                </div>
                                <div className="row">
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CooImage} alt="image" />
                                            </div>
                                            <h5>Prasad Deshpande</h5>
                                            <h6>COO</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CtoImage} alt="image" />
                                            </div>
                                            <h5>Guido van Rossum</h5>
                                            <h6>CTO</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={VpbImage} alt="image" />
                                            </div>
                                            <h5>James Riebel</h5>
                                            <h6>VP - Business Development</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CdImage} alt="image" />
                                            </div>
                                            <h5>Jaime Summers</h5>
                                            <h6>Creative Director</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={CfoImage} alt="image" />
                                            </div>
                                            <h5>Renee Groves</h5>
                                            <h6>CFO</h6>
                                        </div>
                                    </div>
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={DirectorImage} alt="image" />
                                            </div>
                                            <h5>Priyanka Shelke</h5>
                                            <h6>Director </h6>
                                        </div>
                                    </div>

                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={SvpdImage} alt="image" />
                                            </div>
                                            <h5>Srinivas Kolhapure</h5>
                                            <h6>SVP - Delivery</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="leadership-item">
                                <div className="section-title">
                                    <h2>Our Advisors</h2>
                                </div>
                                <div className="row">
                                    <div className="col card-col">
                                        <div className="card">
                                            <div className="card-image">
                                                <img src={AdvisorImage} alt="image" />
                                            </div>
                                            <h5>Nii A. Ahene</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PartnerEchoSystem />
                <div className="clientele-section" >
                    <div className="container">
                        <div className="section-title">
                            <h2>Clientale</h2>
                            <h3>Trusted by</h3>
                            <p>Founded in 2013, Digialpha is a leading global technology consultancy that integrates strategy, design and software engineering.</p>
                            <div className="mobile-hide">
                                <CustomLink to="#" renderSvg={true}>
                                    Explore all client stories
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                    <div className="sliders-wrap">

                        <div id="css2Marquee1" className="slider-first horizontalMarque">
                            {/* <Slider {...slider_first}> */}
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg1}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg2}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg3}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg4}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg5}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg6}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg7}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg8}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg9}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg10}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg11}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg12}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg13}></img>
                                    </div>
                                </div>
                            {/* </Slider> */}
                        </div>
                        <div id="css2Marquee2" className="slider-first horizontalMarque horizontalMarque2">
                            {/* <Slider {...slider_second}> */}
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg14}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg15}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg16}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg17}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg18}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg19}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg20}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg21}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg22}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg23}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg24}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg25}></img>
                                    </div>
                                </div>
                                <div className="slider-item">
                                    <div className="slider-content">
                                        <img src={MarqueeImg26}></img>
                                    </div>
                                </div>
                            {/* </Slider> */}
                        </div>
                        <div className="desktop-hide mobile-show">
                            <div className="container">
                                <CustomLink to="#" renderSvg={true}>
                                    Explore all client stories
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-csr">
                    <div ref={scollToCSRRef} className="section-title">
                        <h2>Corporate Social Responsibility</h2>
                        <h3>Empowering people and communities</h3>
                    </div>
                    <div ref={csrsliderRef} id="csr-slider-wrap-id" className="csr-slider-wrap">
                        <div className="row no-gutters">
                            <div className="col left-image-col ">
                                {/* <div className="image-box">
                                    <img src={CsrImage1} alt="csr image" />
                                </div> */}
                                <div className="left-image bg-image" style={{ backgroundImage: `url(${CsrImage1})` }}>
                                    {/* <div className="image-box">
                                    <img src={CsrImage1} alt="csr image" />
                                </div> */}
                                </div>
                            </div>
                            <div className="col slider-section">
                                <div className="slider-verticle" onWheel={onWheelSlider}>
                                    <Slider ref={sliderRef} {...slider_csr}>
                                        <div className="slider-item">
                                            <h3><span className="green-text">Environmental rights</span> are human rights.</h3>
                                            <CustomLink to="#" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                        <div className="slider-item">
                                            <h3><span className="green-text">Environmental rights</span> are human rights.</h3>
                                            <CustomLink to="#" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                        <div className="slider-item">
                                            <h3><span className="green-text">Environmental rights</span> are human rights.</h3>
                                            <CustomLink to="#" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className="col right-image-col">
                                {/* <div className="image-box">
                                    <img src={CsrImage2} alt="csr image" />
                                </div> */}
                                <div className="right-image bg-image" style={{ backgroundImage: `url(${CsrImage2})` }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <GetInTouch />
            </div >
        </div >
    );
}

export default AboutUs;
