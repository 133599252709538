import { cardsConstant } from "../Constants";

const initialState = {
  loading: false,
  error: null,
  commissionSummary: null,
};

export default function cardsReducer(state = initialState, action) {
  switch (action.type) {
    case cardsConstant.AFFILIATE_DASHBOARD_SUMMARY:
      return {
        ...state,
        loading: true,
        commissionSummary: null,
        error: null,
      };
    case cardsConstant.AFFILIATE_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        commissionSummary: action.response.data,
        error: null,
      };
    case cardsConstant.AFFILIATE_DASHBOARD_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        commissionSummary: null,
        error: action.error,
      };

    case cardsConstant.ASSIGN_CARDS_REQUEST:
      return {
        ...state,
        cardAssignLoader: true,
        sort: false,
        error: null,
        loading: true,
      };
    case cardsConstant.ASSIGN_CARDS_SUCCESS:
      return {
        ...state,
        cardAssigned: true,
        cardAssignLoader: false,
        emailStatus: action.response.data,
        sort: true,
        error: null,
      };
    case cardsConstant.ASSIGN_CARDS_FAILURE:
      return {
        ...state,
        cardAssignLoader: false,
        error: action.error.response,
        loading: false,
      };

    default:
      return state;
  }
}
