export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  IS_AFFILIATE_USER_SUCCESS : "IS_AFFILIATE_USER_SUCCESS",

  IS_EMAIL_EXIST_REQUEST: "IS_EMAIL_EXIST_REQUEST",
  IS_EMAIL_EXIST_SUCCESS: "IS_EMAIL_EXIST_SUCCESS",
  IS_EMAIL_EXIST_FAILURE: "IS_EMAIL_EXIST_FAILURE",

  FREEMIUM_SIGNUP_REQUEST: "FREEMIUM_SIGNUP_REQUEST",
  FREEMIUM_SIGNUP_SUCCESS: "FREEMIUM_SIGNUP_SUCCESS",
  FREEMIUM_SIGNUP_FAILURE: "FREEMIUM_SIGNUP_FAILURE",

  PREMIUM_SIGNUP_REQUEST: "PREMIUM_SIGNUP_REQUEST",
  PREMIUM_SIGNUP_SUCCESS: "PREMIUM_SIGNUP_SUCCESS",
  PREMIUM_SIGNUP_FAILURE: "PREMIUM_SIGNUP_FAILURE",

  PREMIUM_PLUS_SIGNUP_REQUEST: "PREMIUM_PLUS_SIGNUP_REQUEST",
  PREMIUM_PLUS_SIGNUP_SUCCESS: "PREMIUM_PLUS_SIGNUP_SUCCESS",
  PREMIUM_PLUS_SIGNUP_FAILURE: "PREMIUM_PLUS_SIGNUP_FAILURE",

  UPGRADE_MEMBERSHIP_REQUEST: "UPGRADE_MEMBERSHIP_REQUEST",
  UPGRADE_MEMBERSHIP_SUCCESS: "UPGRADE_MEMBERSHIP_SUCCESS",
  UPGRADE_MEMBERSHIP_FAILURE: "UPGRADE_MEMBERSHIP_FAILURE",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  REST_USER_ID: "REST_USER_ID",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  RESET_SERVER_ERROR: "RESET_SERVER_ERROR",

  GET_USER_DETAIL_REQUEST: "GET_USER_DETAIL_REQUEST",
  GET_USER_DETAIL_SUCCESS: "GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE: "GET_USER_DETAIL_FAILURE",

  UPDATE_USER_DETAIL_REQUEST: "UPDATE_USER_DETAIL_REQUEST",
  UPDATE_USER_DETAIL_SUCCESS: "UPDATE_USER_DETAIL_SUCCESS",
  UPDATE_USER_DETAIL_FAILURE: "UPDATE_USER_DETAIL_FAILURE",

  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",

  ADD_USER_DETAIL_REQUEST: "ADD_USER_DETAIL_REQUEST",
  ADD_USER_DETAIL_SUCCESS: "ADD_USER_DETAIL_SUCCESS",
  ADD_USER_DETAIL_FAILURE: "ADD_USER_DETAIL_FAILURE",

  CANCEL_MEMBERSHIP_REQUEST: "CANCEL_MEMBERSHIP_REQUEST",
  CANCEL_MEMBERSHIP_SUCCESS: "CANCEL_MEMBERSHIP_SUCCESS",
  CANCEL_MEMBERSHIP_FAILURE: "CANCEL_MEMBERSHIP_FAILURE",
  RESET_CANCEL_MEMBERSHIP_STATUS: "RESET_CANCEL_MEMBERSHIP_STATUS",

  GET_GUEST_LIST_REQUEST: "GET_GUEST_LIST_REQUEST",
  GET_GUEST_LIST_SUCCESS: "GET_GUEST_LIST_SUCCESS",
  GET_GUEST_LIST_FAILURE: "GET_GUEST_LIST_FAILURE",

  UPDATE_GUEST_DETAIL_REQUEST: "UPDATE_GUEST_DETAIL_REQUEST",
  UPDATE_GUEST_DETAIL_SUCCESS: "UPDATE_GUEST_DETAIL_SUCCESS",
  UPDATE_GUEST_DETAIL_FAILURE: "UPDATE_GUEST_DETAIL_FAILURE",

  RESET_FORGOT_PASSWORD: "RESET_FORGOT_PASSWORD",

  CONFIRM_EMAIL_REQUEST: "CONFIRM_EMAIL_REQUEST",
  CONFIRM_EMAIL_SUCCESS: "CONFIRM_EMAIL_SUCCESS",
  CONFIRM_EMAIL_FAILURE: "CONFIRM_EMAIL_FAILURE",
};
