import { authConstants } from "../Constants/authConstants";

const initialState = {
  loginData: null,
  errors: "",
  forgotPasswordDetails: "",
  resetPasswordDetails: "",
  loginError: "",
  isUserExist: null,
  freemiumSignupData: null,
  premiumSignupData: null,
  premiumPlusSignupData: null,
  logout: null,
  userData: {},
  user_id: null,
  upgradeMembershipData: null,
  confirmEmailData: null,
  confirmEmailError: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loginData: action.response,
        errors: "",
        passwordError: "",
        addGuessError: "",
      };
    case authConstants.LOGIN_FAILURE: 
      return {
        ...state,
        loginError: action.error,
        forgotPasswordDetails: null,
        error: null,
        loading: true,
      };
    case authConstants.IS_AFFILIATE_USER_SUCCESS:
      return {
        ...state,
        loginError: action.error,
        forgotPasswordDetails: null,
        error: null,
        loading: true,
      };
    case authConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordDetails: action.response,
        loading: true,
      };
    case authConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordDetails: action.response,
        loading: false,
      };
    case authConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        errors: action.error.response.data,
        loading: false,
        forgotPasswordDetails: null,
      };
    case authConstants.RESET_FORGOT_REQUEST:
      return {
        ...state,
        errors: null,
        forgotPasswordDetails: null,
        loading: false,
      };
    case authConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        errors: null,
        forgotPasswordDetails: null,
        loading: true,
      };
    case authConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordDetails: action.response,
        loading: false,
      };
    case authConstants.RESET_PASSWORD_FAILURE:
      return { ...state, errors: action.error.response.data, loading: false };
    case authConstants.RESET_SERVER_ERROR:
      return { ...state, errors: {}, loginError: "" };
    case authConstants.LOGOUT_SUCCESS:
      return { ...state, loginData: null, logout: true };
    case authConstants.GET_USER_DETAIL_REQUEST:
      return { ...state, logout : null };
    case authConstants.GET_USER_DETAIL_SUCCESS:
      return { ...state, userData: action.response.data };
    case authConstants.GET_USER_DETAIL_FAILURE:
      return { ...state, error: action.error.response.data };
    case authConstants.GET_USER_BY_ID_REQUEST:
      return { ...state, user_id: null };
    case authConstants.GET_USER_BY_ID_SUCCESS:
      return { ...state, user_id: action?.response?.data?.user_id };
    case authConstants.GET_USER_BY_ID_FAILURE:
      return { ...state, user_id: null };
    case authConstants.REST_USER_ID:
      return { ...state, user_id: null };

    case authConstants.IS_EMAIL_EXIST_REQUEST:
      return { ...state, isUserExist: null };
    case authConstants.IS_EMAIL_EXIST_FAILURE:
      return { ...state, isUserExist: action?.error?.response?.status };
    case authConstants.IS_EMAIL_EXIST_SUCCESS:
      return { ...state, isUserExist: action.response.status };
    case authConstants.FREEMIUM_SIGNUP_REQUEST:
      return { ...state, isUserExist: null };
    case authConstants.FREEMIUM_SIGNUP_SUCCESS:
      return { ...state, freemiumSignupData: action.response.status };
    case authConstants.FREEMIUM_SIGNUP_FAILURE:
      return { ...state, freemiumSignupData: action?.error?.response?.status };
    case authConstants.PREMIUM_SIGNUP_REQUEST:
      return { ...state, isUserExist: null };
    case authConstants.PREMIUM_SIGNUP_SUCCESS:
      return { ...state, premiumSignupData: action.response.status };
    case authConstants.PREMIUM_SIGNUP_FAILURE:
      return { ...state, premiumSignupData: action?.error?.response?.status };
    case authConstants.PREMIUM_PLUS_SIGNUP_REQUEST:
      return { ...state, isUserExist: null };
    case authConstants.PREMIUM_PLUS_SIGNUP_SUCCESS:
      return { ...state, premiumPlusSignupData: action?.response?.status };
    case authConstants.PREMIUM_PLUS_SIGNUP_FAILURE:
      return {
        ...state,
        premiumPlusSignupData: action?.error?.response?.status,
      };

    case authConstants.UPGRADE_MEMBERSHIP_REQUEST:
      return { ...state, upgradeMembershipData: null };
    case authConstants.UPGRADE_MEMBERSHIP_SUCCESS:
      return { ...state, upgradeMembershipData: action?.response?.status };
    case authConstants.UPGRADE_MEMBERSHIP_FAILURE:
      return {
        ...state,
        upgradeMembershipData: action?.error?.response,
      };
    case authConstants.UPDATE_PASSWORD_REQUEST:
      return { ...state, passwordError: "", loading: true };
    case authConstants.UPDATE_PASSWORD_SUCCESS:
      return { ...state, passwordError: null, loading: false };
    case authConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        passwordError: action?.error?.data?.message,
        loading: false,
      };
    case authConstants.ADD_USER_DETAIL_REQUEST:
      return { ...state, addGuessError: "" };
    case authConstants.ADD_USER_DETAIL_FAILURE:
      return { ...state, addGuessError: action?.error?.data?.message };
    case authConstants.UPDATE_USER_DETAIL_REQUEST:
      return { ...state, addGuessError: "", loading: true };
    case authConstants.UPDATE_USER_DETAIL_SUCCESS:
      return { ...state, loading: false };
    case authConstants.UPDATE_USER_DETAIL_FAILURE:
      return { ...state, loading: false };

    // cancel membership
    case authConstants.CANCEL_MEMBERSHIP_REQUEST:
      return {
        ...state,
        cancelMembershipError: null,
        cancelMembershipSuccess: null,
        message: "",
      };
    case authConstants.CANCEL_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        cancelMembershipSuccess: true,
        message: action?.response?.data?.message,
      };
    case authConstants.CANCEL_MEMBERSHIP_FAILURE:
      return { ...state, cancelMembershipError: action?.error?.data?.message };

    case authConstants.RESET_CANCEL_MEMBERSHIP_STATUS:
      return { ...state, cancelMembershipSuccess: null };

    case authConstants.GET_GUEST_LIST_REQUEST:
      return { ...state, guestList: {} };
    case authConstants.GET_GUEST_LIST_SUCCESS:
      return { ...state, guestList: action?.response?.data?.data };
    case authConstants.GET_GUEST_LIST_FAILURE:
      return { ...state, guestList: {} };

    case authConstants.UPDATE_GUEST_DETAIL_REQUEST:
      return { ...state, updateGuestError: "", updateGuestSuccess: "" };
    case authConstants.UPDATE_GUEST_DETAIL_SUCCESS:
      return { ...state, updateGuestSuccess: action?.response?.data?.data };
    case authConstants.UPDATE_GUEST_DETAIL_FAILURE:
      return { ...state, updateGuestError: action?.error?.data?.message };

    case authConstants.CONFIRM_EMAIL_REQUEST:
      return { ...state, confirmEmailData: null };
    case authConstants.CONFIRM_EMAIL_SUCCESS:
      return { ...state, confirmEmailData: action?.response?.data };
    case authConstants.CONFIRM_EMAIL_FAILURE:
      return { ...state, confirmEmailError: action?.error?.data?.error.message };

    default:
      return state;
  }
};

export default authReducer;
