import { Provider } from "react-redux";
import Root from "./views/Root";
import store from "./Stores/store";
import "./assets/css/style.scss";

function App() {
  
  return (
    <Provider store={store}>
      
      <Root />
    </Provider>
  );
}

export default App;
