import StrategyImage from "../assets/images/home/heroImages/hero1.png";
import heroStrategyImage from "../assets/images/home/heroImages/heroThumb2.png";
import heroStrategyImage2 from "../assets/images/home/heroImages/heroThumb3.png";
import heroStrategyImage4 from "../assets/images/home/heroImages/heroThumb4.png";

const heroData = [
  {
    screen: "strategy-image",
    title: "We Strategize.",
    paragraphs: ["Design and deliver new digital experiences, revenue streams and business models to meet rising customer expectations and accelerate your growth."],
    thumbImage: heroStrategyImage,
    nextSlideLabel: "We design."
  },
  {
    screen: "design-image",
    title: "We design.",
    paragraphs: ["Design and deliver new digital experiences, revenue streams and business models to meet rising customer expectations and accelerate your growth."],
    thumbImage: heroStrategyImage2,
    heroImage: "",
    nextSlideLabel: "We engineer."
  },
  {
    screen: "engineer-image",
    title: "We engineer.",
    paragraphs: ["Design and deliver new digital experiences, revenue streams and business models to meet rising customer expectations and accelerate your growth."],
    thumbImage: heroStrategyImage4,
    heroImage: "",
    nextSlideLabel: "We strategize."
  }
]
const blogData= [
  {
    leftText: "/ Insight — Blog",
    title: "Quality Engineering in Product Development: The Challenges, Approach and Benefits",
    para: "Quality Engineering is the new term of reference for Testing. Product testing services are essential in making sure that a product is of high quality and functions according to customer needs and expectations.",
    thumbImage: "../../assets/images/tmp/blog1.webp",
    // dataColor: "#32353A",
    // dataTextColor: "#DCDFEA",
    dataId: ".blog-section"
  },
  {
    leftText: "/ Insight — Blog",
    title: "The Step by Step Flow, Challenges and solutions in the journey to Data Analytics",
    para: "If I say Today’s Economy is a data Economy it will not be a wrong statement. In every walk of the life there are activities, transactions generating information and storage of data.",
    thumbImage: "../assets/images/tmp/blog1.webp",
    // dataColor: "#33251f",
    // dataTextColor: "#ffb69c",
    dataId: ".blog-section"
  },
  {
    leftText: "/ Insight — Blog",
    title: "The right cloud strategy and diligent execution is need of the hour",
    para: "By reading this statement one may feel what is so great about it that I am trying to tell or share here? There is much deeper meaning to it.",
    thumbImage: "../assets/images/tmp/blog1.webp",
    // dataColor: "#181f32",
    // dataTextColor: "#c1d9ff",
    dataId: ".blog-section"
  },
  {
    leftText: "/ Insight — Blog",
    title: " Digital Journey: Considerations and Best Practices",
    para: "Over the years businesses have grown and their growth was supported by their internal IT by building/procuring and deploying systems/applications.",
    thumbImage: "../assets/images/tmp/blog1.webp",
    // dataColor: "#181f32",
    // dataTextColor: "#c1d9ff",
    dataId: ".blog-section"
  },
  
]


const servicesData= [
  {
    title: "Customer Experience & Design",
    thumbImage: "../assets/images/tmp/blog1.wemp",
    dataColor: "#e1e1e1",
    dataTextColor: "#000",
  },
  {
    title: "Digital Product Engineering",
    thumbImage: "../assets/images/tmp/blog1.wemp",
    dataColor: "#f1f1f1",
    dataTextColor: "#000",
  },
  {
    title: "Data & Analytics",
    thumbImage: "../assets/images/tmp/blog1.wemp",
    dataColor: "#f1f1f1",
    dataTextColor: "#000",
  },
  {
    title: "Digital Engineering & Services",
    thumbImage: "../assets/images/tmp/blog1.wemp",
    dataColor: "#f1f1f1",
    dataTextColor: "#000",
  },
  {
    title: "MVP BetaLab",
    thumbImage: "../assets/images/tmp/blog1.wemp",
    dataColor: "#f1f1f1",
    dataTextColor: "#000",
  },
  {
    title: "Application Services",
    thumbImage: "../assets/images/tmp/blog1.wemp",
    dataColor: "#f1f1f1",
    dataTextColor: "#000",
  }
]
export {heroData, blogData, servicesData}