import { commonConstants } from "../Constants/commonConstants";
import { autoCompleteConstants } from "../Constants/siteConstants/autocompleteConstants";

const initialState = {
  loading: false,
  getNotifiedData: null
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.SHOW_LOADER:
      return { ...state, loading: true };
    case commonConstants.HIDE_LOADER:
      return { ...state, loading: false };

    // Get Notified Email 
    case commonConstants.GET_NOTIFIED_REQUEST:
      return { ...state, getNotifiedData: null };
      
    case commonConstants.GET_NOTIFIED_RESULT:
      debugger;
      return { ...state, getNotifiedData : action.response.data };

    case commonConstants.GET_NOTIFIED_FAILURE:
      return { ...state, getNotifiedData:null };

    default:
      return state;
  }
};
export default commonReducer;
