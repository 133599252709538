import http from "./http";

function getNotifiedService(emailId) {
  return http
    .post(
      `${process.env.REACT_APP_BASE_URL_BLOG}/wp-json/blogs/v1/notify-email`,
        { email: emailId }
    )
    .then((response) => response);
}

export const commonApiService = {
  getNotifiedService,
};
