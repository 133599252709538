import React, { useEffect } from "react";
import { gsap } from 'gsap';
import ServiceContentCard from "../components/ServiceContentCard";
import CustomLink from "../components/Common/CustomLink";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Card1 from "../assets/images/services-detail/card/service-card.png"
import CardImage from "../assets/images/services-detail/card/sampleImg.jpeg";
import CardImage1 from "../assets/images/case-study/application-services-vertical.jpg";
import CardImage2 from "../assets/images/case-study/data-and-Analytics-vertical.jpg";
import CardImage3 from "../assets/images/case-study/digital-engineering-and-services-vertical.jpg";
import CardImage4 from "../assets/images/case-study/MVP-vertical.jpg";
import VtravelLogo from "../assets/images/case-study/vtravel-logo.png";
const ServiceCardNew = () => {
    function animateFrom(elem, direction, index) {
        //console.log(index, "index")
        direction = direction || 1;
        var x = 0,
            y = direction * 100;
        if (elem.classList.contains("gs_reveal_fromLeft")) {
            x = -100;
            y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
            x = 100;
            y = 0;
        }
        // elem.style.transform = "translate(" + x + "px, " + y + "px)";
        // elem.style.backgroundPosition = x + "px, " + y + "px";
        elem.style.opacity = "0";
        gsap.fromTo(elem, { x: x, y: y, autoAlpha: 0 }, {
            duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto"
        });

    }

    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.utils.toArray(".gs_reveal").forEach(function (elem, index) {
            hide(elem); // assure that the element is hidden when scrolled into view
            ScrollTrigger.create({
                trigger: elem,
                onEnter: function () { animateFrom(elem, null, index) },
                onEnterBack: function () { animateFrom(elem, -1, index) },
                onLeave: function () { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        });
        gsap.utils
            .toArray(".card img")
            .forEach((section, i) => {
                const heightDiff =
                    section.offsetHeight - section.parentElement.offsetHeight;

                gsap.fromTo(
                    section,
                    {
                        y: section.parentElement.offsetHeight - 300
                    },
                    {
                        y: -230,
                        ease: "none",
                        scrollTrigger: {
                            trigger: section.parentElement,
                            scrub: true
                        }
                    }
                );
            });
    }, [])

    return (
        <div className="section-service-card">
            <div className="container">
                <div className="cInnerContent">
                    <div className="feature-row row">
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage1} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage2} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage3} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage4} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_left"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                        <div className="col col1">
                            <ServiceContentCard clientLogo={VtravelLogo} sectionClass={"gs_reveal"} ContentClass={"ipsType_right"} cardImage={CardImage} title={"Travel & Hospitality"} subTitle={"Discover, Collect & Sell extraordinary NFTs"} content={"Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices."} buttontext={"Learn More"} buttonUrl={"/case-study-detail"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCardNew;