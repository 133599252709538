import { useEffect, useRef, useState } from "react";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Link } from "react-router-dom";
import Blog from "../../components/Blog";
import CustomLink from "../../components/Common/CustomLink";
import { heroData, servicesData } from "./data";
import Slider from "react-slick";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as Thumb1 } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as MouseSvg } from "../../assets/images/icon/mouse.svg";
// import "../../assets/css/common.scss";
import "../../assets/css/pages/landing.scss";
import Marquee from "../../components/Marquee";
import Services from "../../components/Services";
import AboutSection from "../../components/AboutSection";
import Careers from "../../components/Careers";
import GetInTouch from "../../components/GetInTouch";
import { useDispatch, useSelector } from "react-redux";
import { blogActions } from "../../Stores/Actions/blogAction";

const LandingPage = () => {
  const sliderRef = useRef();
  const nextSlide = () => {
    sliderRef.current.slickNext();
  };
  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    dots: false,
    // autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  // gotoNext = () => {
  //   sliderRef.current.slickNext();
  // }

  // getting blog data from the store
  const blogData = useSelector(
    (state) => state?.blogReducer?.blogResults?.data
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      filter_by: [11],
      page: 1,
      posts_per_page: 4,
      order_by: "a-z",
    };

    dispatch(blogActions(payload));
  }, []);

  return (
    <div id="page" className="site wrapper">
      <div id="feather" className="feather"></div>

      <main id="content" className="site-content main-home-page" role="main">
        {/* 
    <section id="intro" className="full-screen pt-5 blue">
      <h1>A cool title</h1>

      <div id="clouds-layer-1" className="clouds"></div>
      <div id="clouds-layer-2" className="clouds"></div>
    </section> */}

        <section className="home-banner-slider" id="panels">
          <div className="panels-container">
            <Slider {...settings} ref={sliderRef}>
              {heroData.map((hero, index) => {
                return (
                  <div
                    className={`sliders full-screen ${hero.screen}`}
                    key={hero.screen}
                  >
                    <div className="sliderImage"></div>
                    <div className="slider-wrap">
                      <div className="container">
                        <div className="contentWrap">
                          <h1>{hero.title}</h1>
                          {hero.paragraphs.map((para, index) => {
                            return <p key={index}>{para}</p>;
                          })}
                        </div>
                        <div className="heroFooter">
                          <MouseSvg className="mouseImageWrapper" />
                          <div className="heroNavigation">
                            <span className="mobile-hide">
                              0{index + 1}&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;0
                              {heroData.length}
                            </span>
                            <div className="thumbnail-image">
                              <img src={hero.thumbImage} alt="Thumbnail" />
                            </div>
                            <div className="thumbnail-btn-wrapper">
                              <button
                                className="btn typeAnchor custom-link"
                                onClick={() => sliderRef?.current?.slickNext()}
                              >
                                {/* <CustomLink to="#" renderSvg={true}> */}
                                <span>{hero.nextSlideLabel}</span>
                                <div className="arrow">
                                  <div className="head"></div>
                                </div>
                                {/* </CustomLink> */}
                              </button>
                              <span className="desktop-hide mobile-show">
                                0{index + 1}
                                &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;0
                                {heroData.length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
        <AboutSection />
        <Services data={servicesData} />
        <Marquee />

        <Blog isHome={true} data={blogData} />
        {/* Blog section */}
        <Careers />
        <GetInTouch />
      </main>
    </div>
  );
};

export default LandingPage;
