import React, { useEffect } from "react";
import GetInTouch from "../../components/GetInTouch";
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/tmp/blog-detail.png";
import Facebook from "../../assets/images/social-icons/facebook.svg";
import Instagram from "../../assets/images/social-icons/instagram.svg";
import Twitter from "../../assets/images/social-icons/twitter.svg";
import Linkedin from "../../assets/images/social-icons/linkedin.svg";

// import "../../assets/css/common.scss";
import "../../assets/css/pages/blog-detail.scss";
import { useDispatch, useSelector } from "react-redux";
import { blogDetailsActions } from "../../Stores/Actions/blogAction";
import { useParams } from "react-router-dom";
const BlogDetailPage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  // get blog details data from store
  const blogDetail = useSelector(
    (state) => state?.blogReducer?.blogDetails?.data
  );

  useEffect(() => {
    dispatch(blogDetailsActions(params?.id));
  }, []);

  return (
    <div className="wrapper">
      <div className="page-blog-detail">
        <div className="blog-banner-main">
          <div
            className="blog-banner"
            style={{ backgroundImage: `url(${BannerImage})` }}
          >
            <div className="container">
              <div className="banner-content">
                <div className="section-title">
                  <h1>Insights — Blogs</h1>
                  <h3>{blogDetail?.[0]?.title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-content-image-main">
            <div className="container">
              <div
                className="banner-content-image"
                style={{ backgroundImage: `url(${blogDetail?.[0]?.image})` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="blog-detail-content">
          <div className="container">
            <p>
              {blogDetail?.[0]?.description}
              {/* By reading this statement one may feel what is so great about it
              that I am trying to tell or share here ? There is much deeper
              meaning to it. In my role of providing the consulting to the
              various businesses, have come across some jarring truths that
              while businesses have decided and moving ahead in
              selection-adoption of Cloud environment, there is no clear
              strategy around what are the goals (short and long term), what
              will be the investment, ROI and measurement of the same, how would
              cloud help them to be more competitive, what will the timeline and
              risks mitigation etc. etc. most of these are either not addressed
              or were vague. Businesses do have business strategy – documented
              well so why not the cloud Strategy?{" "} */}
            </p>
            <h3>
              That is where the problem begins and results don’t come through …
            </h3>
            <p>
              Let us start by understanding: What is a Cloud Strategy ?<br />
              As simply put, Cloud strategy is your action plan of achieving
              long term success in Cloud. Gartner defines it as “a concise point
              of view on the role of cloud within the organization.” Let us look
              at what could be the main benefits Cloud could bring to the table:
            </p>
            <div className="two-col-layout">
              <ul>
                <li> Reduce IT Costs</li>
                <li> Scalability </li>
                <li> Business Continuity </li>
                <li> Flexibility </li>
              </ul>
              <ul>
                <li> Increased Collaboration </li>
                <li> Disaster Recovery </li>
                <li> Speed </li>
                <li> Quality Control </li>
              </ul>
            </div>
            <p>
              I am sure these benefits any business / org would want to gain and
              also make progress. In the absence of strategy, it is not possible
              to gain the above benefits continuously and also measure pre and
              post results. Let us look at quickly the linkage business issues
              -> potential of cloud to solve it -> linkage to cloud strategy ->
              Possible KPIs to be tarcked linkage to understand it better:{" "}
            </p>
            <div className="four-col-layout">
              <p>Business Issues</p>
              <p>Probable Solution in Cloud </p>
              <p>Probable Solution in Cloud </p>
              <p>Possible KPI/ Measurement </p>
            </div>
            <div className="four-col-layout grey-bg">
              <p>Spending too much on Data Centre (DC) and Licenses </p>
              <p>
                Select the right Cloud Provider and the right model (may be
                Hybrid Cloud ){" "}
              </p>
              <p>
                We will replace on premise Data Centre by Hybrid Cloud in 3
                years – detail plan and actionable{" "}
              </p>
              <p>
                Current DC Cost Vs Cloud Cost ( Capex+Opex) – Reduction by say
                20% to 40%
              </p>
            </div>
            <div className="four-col-layout">
              <p>
                Not sure about the volatility in the business due to fluctuating
                demands and hence cannot plan on huge investments{" "}
              </p>
              <p>
                Select right Cloud Provider model with pay per use model for the
                Infra and licenses{" "}
              </p>
              <p>
                Which set of Applications e.g. Front Store/Ecommerce /Market
                place to be moved to cloud as a Strategy – detail plan and
                actionable{" "}
              </p>
              <p>
                Current Cost of scalability and hosting Vs cloud based model of
                hosting and scalability{" "}
              </p>
            </div>
            <div className="four-col-layout grey-bg">
              <p>
                Want to help the Distributors of the business to quickly get the
                real time reports on the stock, availability of the material.
                There are many new Distributors{" "}
              </p>
              <p>
                Quickly deploy the functionality and enable new and existing
                distributors to access it in a secured way. This can be done on
                the cloud very fast and also with proper security.{" "}
              </p>
              <p>
                New Functionality development across the business demands –
                where it will happen, how it will happen, who will do it and the
                timelines – Cloud Strategy{" "}
              </p>
              <p>
                Time to Deploy? Enablement of the Distributors – how quickly?
              </p>
            </div>
            <p>
              Some Key questions which one need to address to get to the Cloud
              strategy formation are:{" "}
            </p>
            <ul>
              <li>
                {" "}
                What are the key goals to bring in the Cloud ? E.g. Cost
                Savings, Efficiency, Scalability etc.{" "}
              </li>
              <li>
                {" "}
                Who will decide in which cloud to invest into and the
                commercials? E.g. One has to look at ROI etc. by comparing on
                various options.
              </li>
              <li>
                {" "}
                What KPIs, management need to track and at what intervals ?
              </li>
              <li>
                {" "}
                How cloud is going to help the business to be more competitive ?
              </li>
              <li> What risks are involve and the mitigation measures ?</li>
              <li>
                {" "}
                What are the timelines of implementation and who prepares the
                implementation plan ?{" "}
              </li>
              <li>
                {" "}
                What are IT process changes, DR & Business Continuity changes ?
              </li>
              <li> What happens to business change management ?</li>
              <li>
                {" "}
                Who creates the cloud strategy ? Should be the IT leader who
                with a small team together creates the blueprint{" "}
              </li>
            </ul>
            <p>
              In addition to the above key questions to be addressed there needs
              to following activities to be planned which will help cloud
              strategy Execution to be successful:
            </p>
            <ol type="1">
              <li>
                {" "}
                <strong>Executive Sponsorship</strong> – The mandate of moving
                to cloud and the messaging to all need to come from the
                Executive Sponsor which could be COO, CEO, CXO roles explaining
                the rationale behind and what to expect and contribution from
                all.{" "}
              </li>
              <li>
                {" "}
                <strong>Core Team Formation</strong> – There needs to be cross
                functional team created - comprising of business leadership, IT
                Leadership, Functional Leadership etc. where there is
                representation of teams implementing it and the teams which are
                going through this change, additionally the Cloud SMEs need to
                be part of the team (if not available internally need to be
                hired or through consulting role get them available)
              </li>
              <li>
                {" "}
                <strong>Change Management</strong> – There needs to a formal
                intervention of change management, meaning there has to be plan
                to conduct change management workshops defining the changes in
                the process, functionality of the impacted functions and walk
                them through the proposed changes if any. For the functions
                where there is no change, the communication still needs to go.{" "}
              </li>
              <li>
                {" "}
                <strong>Governance and communication</strong> – There needs to
                be a governance meetings to be set with regular cadence –
                defining what will be tracked and reported, at what frequency,
                who will attend, what is the escalation path and who will
                communicate to the entire business the updates.{" "}
              </li>
              <li>
                {" "}
                <strong>Budgetary Planning</strong> – The Core team needs to
                plan with the FIN – Leadership team, the investments needed in
                the form of CPAEX and OPEX across the plan duration and also the
                savings which will be achieved by usage of clouds.{" "}
              </li>
            </ol>
            <div className="social-links">
              <ul>
                <li>
                  <a href="#" target="_blank">
                    <img src={Facebook} />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src={Instagram} />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src={Linkedin} />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <img src={Twitter} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="blog-pagination">
          <div className="container">
            <div className="row-wrap">
              <div className="no-gutters row">
                <div className="col blog-col blog-title">
                  <button type="button" className="btn-next">
                    Next
                  </button>
                </div>
                <div className="col blog-col">
                  <h3>
                    <a href="/blog-details">
                      Behind our Digital Transformation campaign
                    </a>
                  </h3>
                </div>
                <div className="col blog-col">
                  <p>
                    Founded in 2013, Digialpha is a leading global technology
                    consultancy that integrates strategy, design and software
                    engineering to enable enterprises.
                  </p>
                </div>
              </div>
              <div className="no-gutters row">
                <div className="col blog-col blog-title">
                  <button type="button" className="btn-pre">
                    Pre
                  </button>
                </div>
                <div className="col blog-col">
                  <h3>
                    <a href="/blog-detail">
                      Behind our Digital Transformation campaign
                    </a>
                  </h3>
                </div>
                <div className="col blog-col">
                  <p>
                    Founded in 2013, Digialpha is a leading global technology
                    consultancy that integrates strategy, design and software
                    engineering to enable enterprises.
                  </p>
                </div>
              </div>
            </div>
            <div className="explore-btn">
              <div className="row no-gutters">
                <div className="col blog-title"></div>
                <div className="col">
                  <CustomLink to="/blogs" renderSvg={true}>
                    <h5>Explore the Journal </h5>
                  </CustomLink>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GetInTouch />
    </div>
  );
};
export default BlogDetailPage;
