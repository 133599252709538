import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Blog from "../../components/Blog";
import WhyBetaCraft from "../../components/Common/WhyBetaCraft";
import CustomLink from "../../components/Common/CustomLink";
import GetInTouch from "../../components/GetInTouch";
import Offerings from "../../components/Offerings";
import { ReactComponent as RightArrow } from "../../assets/images/icon/right-arrow.svg"
import companyLogo from "../../assets/images/logo/company-logo-1.png"
import companyLogo2 from "../../assets/images/logo/ccr.png"
import windowsIcon from "../../assets/images/marque-img/windows.png"
import imbIcon from "../../assets/images/marque-img/ibm.png"
import awsIcon from "../../assets/images/marque-img/aws.png"
import salesForceIcon from "../../assets/images/marque-img/salesforce.png"
import redHatIcon from "../../assets/images/marque-img/red-hat.png"
import offertinImg1 from "../../assets/images/Customer-Ex-offering/Digital-Marketing.jpg"
import offertinImg2 from "../../assets/images/Customer-Ex-offering/CX-strategy.jpg"
import offertinImg3 from "../../assets/images/Customer-Ex-offering/CX-Design.jpg"
import offertinImg4 from "../../assets/images/Customer-Ex-offering/CX-Engg.jpg"
import offertinImg5 from "../../assets/images/Customer-Ex-offering/Design-thinking.jpg"

import { ReactComponent as VideoIcon } from "../../assets/images/icon/video-btn-icon.svg";
import { blogData } from "../../pages/data";
import Slider from "react-slick";
import "../../assets/css/pages/home.scss";

import { Anchor } from 'antd';
const { Link } = Anchor;

const CustomerExperienceAndDesignPage = (props) => {
    // const tabSliderRef = useRef();

    
    const [tabSliderRef, setTabSliderRef] = useState();
    const [tabThumbRef, setTabThumbRef] = useState();
    const [indexInit, setindexInit] = useState(0);
    const [images, setImages] = useState([companyLogo2, companyLogo, companyLogo2, companyLogo]);
    

    const [scroll, setScroll] = useState(false);
    const [scrollSuccessStory, setScrollSuccessStory] = useState(false);
    const [scrollContainerAnimate, setScrollContainerAnimate] = useState(false);
    const [scrollContainerAnimate2, setScrollContainerAnimate2] = useState(false);
    const [scrolDifferentiatorsSection, setScrolDifferentiatorsSection] = useState(false);

    const [targetOffset, setTargetOffset] = useState(undefined);
    useEffect(() => {
      setTargetOffset(window.innerHeight / 2);
    }, []);

    useEffect(() => {
        const element = document.getElementById("overviewSection");
        let pos = element.offsetTop - (window.innerHeight/2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos) {
                setScroll(true)
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false)
            })
        }
    }, []);
    useEffect(() => {
        const element2 = document.getElementById("successStory");
        let pos2 = element2.offsetTop - (window.innerHeight/1.2);
        window.addEventListener("scroll", () => {
            if ( window.scrollY > pos2) {
                setScrollSuccessStory(true);
            } else {
                setScrollSuccessStory(false)
            }
        });
    }, []);
    // useEffect(() => {
    //     const element3 = document.getElementById("containerAnimate");
    //     let pos3 = element3.offsetTop - (window.innerHeight/4);
    //     window.addEventListener("scroll", () => {
    //         if ( window.scrollY > pos3) {
    //             setScrollContainerAnimate(true)
    //         } else {
    //             setScrollContainerAnimate(false)
    //         }
    //     });
    // }, []);

    // useEffect(() => {
        
    //     let pos5 = document.getElementById("containerAnimate").offsetTop + 71;
    //     let btmPos = document.getElementById("successStory").offsetTop + (window.innerHeight);
    //     window.addEventListener("scroll", () => {
    //         if ( window.scrollY > pos5) {
    //             setScrollContainerAnimate2(true)
    //         } else {
    //             setScrollContainerAnimate2(false)
    //         }
    //     });
    // }, []);

    useEffect(() => {
        let pos3 = document.getElementById("differentiatorsSection").offsetTop - (window.innerHeight/2);
        window.addEventListener("scroll", () => {
            if (!scroll && window.scrollY > pos3) {
                setScrolDifferentiatorsSection(true);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScrolDifferentiatorsSection(false)
            })
        }
    }, []);
    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
     


    function hide(elem) {
        gsap.set(elem, { autoAlpha: 0 });
    }
    

    let tabSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        center: true,
        autoplay: true,
        autoplaySpeed: 5000
    }
    const offeringList = [
        {
            id: "slideV21",
            title: "Digital Marketing, Brand,<br/> Content and Creative",
            copy: "Digital Marketing, Brand, Content and Creative - <br />Let our full-stack experience agencies illuminate your<br/> company's personality through design, performance <br/>video production, identity, and strategy.",
            image: offertinImg1
        },
        {
            id: "slideV22",
            title: "CX Strategy",
            copy: "Create a vision for the experience you want <br />to deliver and form the plan you <br /> need to make it happen.",
            image: offertinImg2
        },
        {
            id: "slideV23",
            title: "CX Design",
            copy: "At Digialpha, we offer great customer experience creation through our <br />expertise and methodology by understanding the user requirements, <br />issues and challenges. This approach gives business an edge to be <br />right at the first time creating exceptional customer journeys.",
            image: offertinImg3
        },
        {
            id: "slideV24",
            title: "CX Engineering",
            copy: "Once the CX design is approved by the business we bring in<br/> Technology Engineering to deliver the customer<br/> experience with scalability",
            image: offertinImg4
        },
        {
            id: "slideV25",
            title: "Design Thinking",
            copy: "<span>Design thinking is a non-linear, iterative process that<br /> teams use to understand users, challenge assumptions,<br /> redefine problems and create innovative solutions to<br />prototype and test. Involving five phases—Empathize,<br />Define, Ideate, Prototype and Test—it is most useful<br />to tackle problems that are ill-defined or unknown.",
            image: offertinImg5
        }
         
    ]
    return <div className='wrapper'>
        <div className='service-wrapper home-wrapper'>
            <div className="home-page" ref={ref}>
                <div className="section-1 first">
                    <div className="product-banner">
                        <div className="banner-section customer-experence-bg">
                            <div className="container banner-container">
                                <div className="left-content first-paragraph">
                                    <h5 className="tagline">Customer Experience & Design</h5>
                                    <h1 className="tagline">Design, deliver, and grow digital products, services, and experiences</h1>
                                    <p className="banner-para">Customer Experience focus is a must for the business to be successful today and hence we address it through our experienced professionals and solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomLink to="#" className="white-wrapper" renderSvg={false}>
                        <h5>Featured Whitepaper </h5>
                        <h4><span>A tool-first,
                            automated approach to scaling your </span><span>DevSecOps organization{" "}<RightArrow /></span>
                        </h4>
                    </CustomLink>
                </div>
                <div className={scroll ? "overview-section pd-top animate" : "overview-section pd-top"} id="overviewSection">
                    <div className="container text-wrap">
                        <h5>Overview</h5>
                        <div className="overview-para-1">
                            <p>Customer Experience is key to the success of any business and also drives the Digital journey of the business very well. Therefore it is important to put customers as the focus and drive through the transformation of the business processes and technology.</p>
                        </div>
                        <div className="overview-para-2">
                            <p>We at Digialpha have certified HFI professionals and frameworks to assess, measure and create great customer experience which will increase the business and also bring in customer delight.</p>
                        </div>
                        <div className="float-clear"></div>
                    </div>
                </div>
                <Offerings offeringList={offeringList} />
                {/* <div className={scrollSuccessStory ? "animateOuter removeFixedPosition" : "animateOuter"} id="animateOuter">
                    <div className={scrollContainerAnimate2 ? "scrollAnimate offering-section scrollBarSticky" : "scrollAnimate offering-section"}>
                        <div className={scrollContainerAnimate ? "containerAnimate showTabs" : "containerAnimate"} id="containerAnimate">           
                            <div className='scollTabs'>
                                <Anchor affix={false}  targetOffset={targetOffset} offsetTop={100} >
                                    <Link href="#slide1" title="slide1"  />
                                    <Link href="#slide2" title="slide2"  />
                                    <Link href="#slide3" title="slide3"  />
                                    <Link href="#slide4" title="slide4"  />
                                    <Link href="#slide5" title="slide5"  />
                                </Anchor>
                            </div>
                            
                            
                            <div className="text offering-content" >
                                <Anchor targetOffset={targetOffset} offsetTop={100} >
                                    <Link href="#slide1" title={<img src={offertinImg1} />}  />
                                    <Link href="#slide2" title={<img src={offertinImg2} />} />
                                    <Link href="#slide3" title={<img src={offertinImg3} />} />
                                    <Link href="#slide4" title={<img src={offertinImg4} />} />
                                    <Link href="#slide5" title={<img src={offertinImg5} />} />
                                </Anchor>
                                
                                <div id="slide1" className='slider-item '>
                                    <div className="p-one row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1><span>Digital Marketing, Brand,</span><span> Content and Creative</span></h1>
                                            <p><span>Digital Marketing, Brand, Content and Creative - </span>
                                                <span>Let our full-stack experience agencies illuminate your</span>
                                                <span> company's personality through design, performance </span><span>video production, identity, and strategy.</span>
                                            </p>
                                        </div>
                                        <div className="laptop offering-img col">
                                            <div className='img-one '><div className='img-1'><img src={offertinImg1} /></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="slide2" className='slider-item '>
                                    <div className="p-two row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1>CX Strategy</h1>
                                            <p> <span>Create a vision for the experience you want</span> <span>to deliver and form the plan you </span> <span> need to make it happen.</span>
                                            </p>
                                        </div>
                                        <div className="laptop offering-img col">
                                        <div className='img-one '><div className='img-1'><img src={offertinImg2} /></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="slide3" className='slider-item '>
                                    <div className="p-three row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1>CX Design</h1>
                                            <p>
                                            <span>At Digialpha, we offer great customer experience creation through our </span> 
                                            <span>expertise and methodology by understanding the user requirements, </span>
                                            <span>issues and challenges. This approach gives business an edge to be </span> 
                                            <span> right at the first time creating exceptional customer journeys.</span>
                                            </p>
                                        </div>
                                        <div className="laptop offering-img col">
                                        <div className='img-one '><div className='img-1'><img src={offertinImg3} /></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="slide4" className='slider-item '>
                                    <div className="p-three row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1>CX Engineering</h1>
                                            <p>
                                            <span>Once the CX design is approved by the business we bring in</span>
                                            <span> Technology Engineering to deliver the customer</span>
                                            <span> experience with scalability</span> 
                                            </p>
                                        </div>
                                        <div className="laptop offering-img col">
                                        <div className='img-one '><div className='img-1'><img src={offertinImg4} /></div></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="slide5" className='slider-item '>
                                    <div className="p-three row ">
                                        <div className="content-1 col">
                                            <h5>Our offerings </h5>
                                            <h1>Design Thinking</h1>
                                            <p>
                                            <span>Design thinking is a non-linear, iterative process that</span>
                                            <span> teams use to understand users, challenge assumptions,</span>
                                            <span> redefine problems and create innovative solutions to</span> 
                                                <span>prototype and test. Involving five phases—Empathize,</span>
                                                <span>Define, Ideate, Prototype and Test—it is most useful</span> 
                                                <span>to tackle problems that are ill-defined or unknown.</span>
                                            </p>
                                        </div>
                                        <div className="laptop offering-img col">
                                        <div className='img-one '><div className='img-1'><img src={offertinImg5} /></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div id='successStory'  className="success-story-section">
                    <div className="container pd-top">
                        <h5>Client Success Stories </h5>
                        <h1><span>Our Work Drives</span><span>Innovation Forward</span></h1>
                    </div>
                    <div className="tab-menu" id="tabs">
                        <div className='container' >
                            <Slider
                                asNavFor={tabThumbRef}
                                ref={(slider2) => setTabThumbRef(slider2)}
                                beforeChange={(e) => setindexInit(e + 1)}
                                arrows={true}
                                slidesToShow={4}
                                focusOnSelect={true}
                                autoplay={true}
                                speed={500}
                                slidesToScroll={1}
                                autoplaySpeed={5000}
                                pauseOnHover={false}
                            >
                                {images.map((ele, index) => {
                                    return <div className="imagesGroupWrap" key={index}>
                                        <img src={ele} />
                                    </div>
                                })}

                            </Slider>

                        </div>
                    </div>
                    <div className="sotry-tabs tab-container">

                        <div className="tab-content">
                            <Slider
                                className='tabSlider'
                                asNavFor={tabSliderRef}
                                {...tabSliderSettings}
                                ref={(tabSliderRef) => {
                                    setTabThumbRef(tabSliderRef);
                                }}
                                pauseOnHover={false}
                            // autoplay={true}
                            // speed={500}
                            // slidesToScroll={1}
                            // autoplaySpeed={500}
                            >
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                                {/* <button className="hoverMebottom button">Read
												the full story.</button> */}
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar<button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                                <div>
                                    <div className="tab">
                                        <div className="col-6 tab-left-section"><button className="audio-btn">Audio
                                            Visual (AV) </button>
                                            <div className="mobile-logo">
                                                <img src={companyLogo2} alt="company-logo-1" />
                                            </div>
                                            <h3><span>Simplifying moving to cloud for a </span><span>leading
                                                North American AV solutions</span><span>company</span></h3>
                                            <p>
                                                Today every software is being viewed and thought of as a Product. Every new Digital application is also thought of as a product hence Product Engineering is becoming the foundation to the Digital world.
                                            </p>
                                            <div className="progress-bar">
                                                <CustomLink to="#" renderSvg={true}>
                                                    Read the full story
                                                </CustomLink>
                                            </div>
                                        </div>
                                        <div className="col-6 tab-right-section">
                                            <div className="tab-video ccr">
                                                <div className="video-text">
                                                    <p>Digialpha offered what we needed out of the box – plus we
                                                        wanted a system that we could reason about,
                                                        good documentation,
                                                        and had existing integrations.</p>
                                                    <h5>Andrei Horak,
                                                        CTO at Zensar <button><VideoIcon /></button></h5>
                                                </div>
                                            </div>
                                            <div className="progress-bar mobileShow"><CustomLink to="#" renderSvg={true}>
                                                Read the full story
                                            </CustomLink></div>
                                        </div>
                                        <div className="float-clear"></div>
                                    </div>
                                </div>

                            </Slider>
                        </div>


                    </div>
                </div>
                <WhyBetaCraft />

                <div className="ecosystem-section">
                    <div className="row container ">
                        <div className="col-1 ecosystem-content">
                            <h5>Partner Ecosystem</h5>
                            <h1>We are a partnership driven company
                            </h1>
                            <p>We form partnerships and strategic alliances with world-class
                                organisations to expand our service offerings and deliver
                                comprehensive solutions to clients. Strategic alliances with the
                                world’s leading companies enable us to provide complete
                                solutions to your business and IT challenges. </p>
                            <div className="progress-bar mobileHide">
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                                {/* <button className="hoverMebottom button"></button> */}
                            </div>
                        </div>
                        <div className="col-2 ecosystem-img">
                            <div className="marque-section ">
                                <div className="area">
                                    <ul className="circles">
                                        <li className="img-4"><img src={salesForceIcon} /></li>
                                        <li className="img-2"><img src={imbIcon} /></li>
                                        <li className="img-3"><img src={awsIcon} /></li>
                                        <li className="img-1"><img src={windowsIcon} /></li>
                                        <li className="img-5"><img src={redHatIcon} /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="progress-bar mobileShow">
                                {/* <button className="hoverMebottom button">Discover More</button> */}
                                <CustomLink to="#" renderSvg={true}>
                                    Learn More
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Blog data={blogData} className="gray-section" /> */}
                <GetInTouch />
                {/*  <div className="insight-scetion">
                    <div className="container">
                        <div className="heading pd-top">
                            <div className="heading-text">
                                <h5>Latest Thinkings </h5>
                                <h1>News and Insights </h1>
                            </div>
                            <div className="slider-btn">
                                <button onClick={prevSlide} className="pre-btn slide-arrow prev-arrow icon-arrow-left"></button>
                                <button onClick={nextSlide} className="next-btn slide-arrow next-arrow icon-arrow-right"></button>
                            </div>
                        </div>
                    </div>
                </div>
            <Blog /> */}
            </div>
        </div>
    </div>
}

export default CustomerExperienceAndDesignPage;