import { blogConstants } from "../Constants";

const initialState = {
  blogResults: null,
  loading: false,
  blogErrors: null,
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case blogConstants.BLOG_REQUEST:
      return {
        ...state,
        blogResults: null,
        loading: true,
        blogErrors: null,
      };
    case blogConstants.BLOG_SUCCESS:
      return {
        ...state,
        blogResults: action.response.data,
        loading: false,
        blogErrors: null,
      };
    case blogConstants.BLOG_FAILURE:
      return {
        ...state,
        blogResults: null,
        loading: true,
        blogErrors: action.error,
      };
    case blogConstants.FEATURED_BLOG_REQUEST:
      return {
        ...state,
        featuredBlog: null,
        loading: true,
        featuredBlogError: null,
      };
    case blogConstants.FEATURED_BLOG_SUCCESS:
      return {
        ...state,
        featuredBlog: action.response.data,
        loading: false,
        featuredBlogError: null,
      };
    case blogConstants.FEATURED_BLOG_FAILURE:
      return {
        ...state,
        featuredBlog: null,
        loading: false,
        featuredBlogError: action.error,
      };
    case blogConstants.BLOG_DETAILS_REQUEST:
      return {
        ...state,
        blogDetails: null,
        loading: true,
        blogDetailsError: null,
      };
    case blogConstants.BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        blogDetails: action.response.data,
        loading: false,
        blogDetailsError: null,
      };
    case blogConstants.BLOG_DETAILS_ERROR:
      return {
        ...state,
        blogDetails: null,
        loading: true,
        blogDetailsError: action.error,
      };
    default:
      return state;
  }
};

export default blogReducer;
