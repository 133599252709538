import React, { useEffect, useRef, useState } from "react";
import BannerContent from "../../components/BannerContent";
import GetInTouch from "../../components/GetInTouch";
import CheckboxMenu from "../../components/Common/MultipleSelect";
import ServiceCardNew from "../../components/ServiceCardNew";
import "../../assets/JS/index.js";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import "../../assets/css/menu.scss";
import { ReactComponent as RightArrow } from "../../assets/images/icon/gray-arrow-right.svg";
import { ReactComponent as RightArrow2 } from "../../assets/images/icon/right-arrow2.svg";
import { ReactComponent as LeftArrow2 } from "../../assets/images/icon/left-arrow2.svg";
import Setting from "../../assets/images/icon/setting.svg";
import Close from "../../assets/images/icon/close.svg";
import OfcLogo from "../../assets/images/case-study/official-nft-logo.png";
import VtravelLogo from "../../assets/images/case-study/vtravel-logo.png";
import CustomLink from "../../components/Common/CustomLink";
import BannerImage from "../../assets/images/case-study/case-study-banner.png";
import CardImage from "../../assets/images/case-study/card-image.png";

import "../../assets/css/pages/case-study.scss";
const CaseStudy = () => {
  const onCheckboxChange = (selection) => {
    //console.log(selection);
  };
  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <a>
          <LeftArrow2 />
        </a>
      );
    }

    if (type === "next") {
      return (
        <a>
          <RightArrow2 />
        </a>
      );
    }

    return originalElement;
  };
  const [MobilePopup, setMobilePopup] = useState(false)
  return (
    <div className="wrapper">
      <div className="page-case-study">
        <BannerContent
          BackgroundImages={BannerImage}
          title={"Blockchain"}
          subTitle={"Discover, Collect & Sell extraordinary NFTs"}
        >
          <div className="content-wrap">
            <p>
              Create a beautiful NFT product on the world’s first NFT
              marketplace
            </p>
            <div className="learn-more-wrap">
              <CustomLink
                to="/case-study-detail"
                renderSvg={false}
                className="typeButton"
              >
                Learn More <RightArrow className="right-arrow" />
              </CustomLink>
              <div className="client-logo">
                <img src={OfcLogo} alt="logo" />
              </div>
            </div>
          </div>
        </BannerContent>
        <div className="case-study-cards-wrap">
          <div className="container">
            <div className="sortWrapper-main">
              <div className="selectWrapper-main p-relative">
                <div className="case-heading desktop-hide mobile-show">
                  <h2>Case Studies</h2>
                </div>
                <div className="toggle-wrap desktop-hide mobile-show">
                  <button type="button" className="toggle" onClick={() => setMobilePopup(true)}><img src={Setting} /></button>
                  {MobilePopup && <div className="filter-popup">
                    <div className="filter-head">
                      <h3>Filter by</h3>
                      <button className="close-btn" type="button" onClick={() => setMobilePopup(false)}>
                        <img src={Close} />
                      </button>
                    </div>
                    <div className="filter-list-wrap">
                      <div className="filter-list">
                        <h2>Services</h2>
                        <ul>
                          <li>
                            {" "}
                            <input
                              type="checkbox"
                              className="filter-check"
                            />{" "}
                            Customer Experience & Design
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            Digital Product Engineering
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            Data & Analytics
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            Digital Engineering & Services
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            MVP BetaLab
                          </li>
                        </ul>
                      </div>
                      <div className="filter-list">
                        <h2>Services</h2>
                        <ul>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            Travel
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            Martech
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />{" "}
                            Blockchain
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            Finance
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            GIS
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            E-commerce
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            Software & Hi-Tech
                          </li>
                          <li>
                            <input type="checkbox" className="filter-check" />
                            elearning
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="apply-btn-wrap">
                      <button className="apply-btn" type="submit">
                        Apply
                      </button>
                    </div>
                  </div>
                  }
                </div>

                <div className="selectWrapper mobile-hide">
                  <CheckboxMenu
                    options={[
                      "Analyst Insight",
                      "Blog",
                      "Brochure",
                      "Case Study",
                      "eBook",
                      "White Paper",
                    ]}
                    value={["Analyst Insight", "Blog", "Brochure"]}
                    onChange={onCheckboxChange}
                    placeholder="Service"
                  />
                </div>
                <div className="selectWrapper mobile-hide">
                  <CheckboxMenu
                    options={[
                      "Analyst Insight",
                      "Blog",
                      "Brochure",
                      "Case Study",
                      "eBook",
                      "White Paper",
                    ]}
                    value={["Analyst Insight", "Blog", "Brochure"]}
                    onChange={onCheckboxChange}
                    placeholder="Industry"
                  />
                </div>
              </div>
            </div>
            <div className="case-study-wrapper">
              {/* <div className="row case-study-row">
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col case-study-col">
                                    <div className="case-study-card card">
                                        <div className="card-image" style={{ backgroundImage: `url(${CardImage})` }}>
                                            <div className="logo-image">
                                                <img src={VtravelLogo} alt="client logo" />
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <h4>Travel & Hospitality</h4>
                                            <h3>Discover, Collect & Sell extraordinary NFTs</h3>
                                            <p>Using modern day design thinking approach and frameworks, integrated with devops toolchain and with a wide variety of technology choices.</p>
                                            <CustomLink to="/case-study-detail" renderSvg={true}>
                                                Learn More
                                            </CustomLink>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <ServiceCardNew />
            </div>
            <div className="custom-pagination-wrap">
              <div className="container">
                <Pagination total={500} itemRender={itemRender} />
              </div>
            </div>
          </div>
        </div>
        <GetInTouch />
      </div>
    </div>
  );
};

export default CaseStudy;
