import React, { Component } from "react";

export class ContactCard extends Component {
  render() {
    return (
      <div className="card-wrapper">
        <div className="container">
          <div className="section-heading">
            <h3>Our Locations</h3>
            <h2>Meet us at..</h2>
          </div>
          <div className="card-section">
            <div className="card card-1 ">
              <div className="card-content">
                <h3>HQ - Pune, India</h3>
                <span>Supreme Headquarters,</span>
                <span> Baner, Pune, MH, India 411045</span>
                <a href="tel:+61 2 1223 1500">Phone: +61 2 1223 1500</a>
              </div>
            </div>

            <div className="card card-2 ">
              <div className="card-content">
                <h3>San Diego, USA</h3>
                <span>3663 Garrison Street,San Diego, </span>
                <span>California USA 92106</span>
                <a href="tel:+61 2 1223 1500">Phone: +61 2 1223 1500</a>
              </div>
            </div>

            <div className="card card-3 ">
              <div className="card-content">
                <h3>Singapore, Singapore</h3>
                <span>45 Lengkok Bahru, </span>
                <span> #03-229, Singapore 150045</span>
                <a href="tel:+61 2 1223 1500">Phone: +61 2 1223 1500</a>
              </div>
            </div>

            <div className="card card-4 ">
              <div className="card-content">
                <h3>Las Vegas, USA</h3>
                <span>Suite 1, central chambers, </span>
                <span> Ealing W5 2NR, London, UK</span>
                <a href="tel:+61 2 1223 1500">Phone: +61 2 1223 1500</a>
              </div>
            </div>

            <div className="card card-5 ">
              <div className="card-content">
                <h3>Dubai, UAE</h3>
                <span>45 Lengkok Bahru, </span>
                <span> #03-229, Singapore 150045 </span>
                <a href="tel:+61 2 1223 1500"> Phone: +61 2 1223 1500</a>
              </div>
            </div>

            <div className="card card-6 ">
              <div className="card-content">
                <h3>London, UK</h3>
                <span>Suite 1, central chambers, </span>
                <span> Ealing W5 2NR, London, UK</span>
                <a href="tel:+61 2 1223 1500"> Phone: +61 2 1223 1500</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactCard;
