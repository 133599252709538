export const hotelConstants = {
  HOTEL_GET_AVABILITY: "HOTEL_GET_AVABILITY",
  HOTEL_GET_AVABILITY_SUCCESS: "HOTEL_GET_AVABILITY_SUCCESS",
  HOTEL_GET_AVABILITY_FAILURE: "HOTEL_GET_AVABILITY_FAILURE",

  HOTEL_RECHECK_PRICE_REQUEST: "HOTEL_RECHECK_PRICE_REQUEST",
  HOTEL_RECHECK_PRICE_SUCCESS: "HOTEL_RECHECK_PRICE_SUCCESS",
  HOTEL_RECHECK_PRICE_FAILURE: "HOTEL_RECHECK_PRICE_FAILURE",

  GET_STRIPE_INTENT_REQUEST: "GET_STRIPE_INTENT_REQUEST",
  GET_STRIPE_INTENT_SUCCESS: "GET_STRIPE_INTENT_SUCCESS",
  GET_STRIPE_INTENT_FAILURE: "GET_STRIPE_INTENT_FAILURE",

  CONFIRM_PAYMENT_REQUEST: "CONFIRM_PAYMENT_REQUEST",
  CONFIRM_PAYMENT_SUCCESS: "CONFIRM_PAYMENT_SUCCESS",
  CONFIRM_PAYMENT_FAILURE: "CONFIRM_PAYMENT_FAILURE",

  BOOK_HOTEL_REQUEST: "BOOK_HOTEL_REQUEST",
  BOOK_HOTEL_SUCCESS: "BOOK_HOTEL_SUCCESS",
  BOOK_HOTEL_FAILURE: "BOOK_HOTEL_FAILURE",

  SEARCH_HOTEL_REQUEST: "SEARCH_HOTEL_REQUEST",
  SEARCH_HOTEL_SUCCESS: "SEARCH_HOTEL_SUCCESS",
  SEARCH_HOTEL_FAILURE: "SEARCH_HOTEL_FAILURE",

  SEARCH_HOTEL_CORD_REQUEST: "SEARCH_HOTEL_CORD_REQUEST",
  SEARCH_HOTEL_CORD_SUCCESS: "SEARCH_HOTEL_CORD_SUCCESS",
  SEARCH_HOTEL_CORD_FAILURE: "SEARCH_HOTEL_CORD_FAILURE",

  GET_USER_BOOKING_DETAILS: "GET_USER_BOOKING_DETAILS",
  GET_USER_BOOKING_DETAILS_SUCCESS: "GET_USER_BOOKING_DETAILS_SUCCESS",
  GET_USER_BOOKING_DETAILS_FAILURE: "GET_USER_BOOKING_DETAILS_FAILURE",

  DOWNLOAD_INVOICE: "DOWNLOAD_INVOICE",
  DOWNLOAD_INVOICE_SUCCESS: "DOWNLOAD_INVOICE_SUCCESS",
  DOWNLOAD_INVOICE_FAILURE: "DOWNLOAD_INVOICE_FAILURE",

  EMAIL_BOOKING: "EMAIL_BOOKING",
  EMAIL_BOOKING_SUCCESS: "EMAIL_BOOKING_SUCCESS",
  EMAIL_BOOKING_FAILURE: "EMAIL_BOOKING_FAILURE",

  CANCEL_BOOKING: "CANCEL_BOOKING",
  CANCEL_BOOKING_SUCCESS: "CANCEL_BOOKING_SUCCESS",
  CANCEL_BOOKING_FAILURE: "CANCEL_BOOKING_FAILURE",

  VALIDATE_ZIP: "VALIDATE_ZIP",
  VALIDATE_ZIP_SUCCESS: "VALIDATE_ZIP_SUCCESS",
  VALIDATE_ZIP_FAILURE: "VALIDATE_ZIP_FAILURE",

  HOTEL_DETAILS_REQUEST: "HOTEL_DETAILS_REQUEST",
  HOTEL_DETAILS_SUCCESS: "HOTEL_DETAILS_SUCCESS",
  HOTEL_DETAILS_FAILURE: "HOTEL_DETAILS_FAILURE",

  HOTEL_PRICE_REQUEST: "HOTEL_PRICE_REQUEST",
  HOTEL_PRICE_SUCCESS: "HOTEL_PRICE_SUCCESS",
  HOTEL_PRICE_FAILURE: "HOTEL_PRICE_FAILURE",

  HOTEL_ROOMS_REQUEST: "HOTEL_ROOMS_REQUEST",
  HOTEL_ROOMS_SUCCESS: "HOTEL_ROOMS_SUCCESS",
  HOTEL_ROOMS_FAILURE: "HOTEL_ROOMS_FAILURE",

  GET_USER_BOOKINGS: "GET_USER_BOOKINGS",
  GET_USER_BOOKINGS_SUCCESS: "GET_USER_BOOKINGS_SUCCESS",
  GET_USER_BOOKINGS_FAILURE: "GET_USER_BOOKINGS_FAILURE",

  GET_CANCELLED_BOOKINGS: "GET_CANCELLED_BOOKINGS",
  GET_CANCELLED_BOOKINGS_SUCCESS: "GET_CANCELLED_BOOKINGS_SUCCESS",
  GET_CANCELLED_BOOKINGS_FAILURE: "GET_CANCELLED_BOOKINGS_FAILURE",

  GET_COMPLETED_BOOKINGS: "GET_COMPLETED_BOOKINGS",
  GET_COMPLETED_BOOKINGS_SUCCESS: "GET_COMPLETED_BOOKINGS_SUCCESS",
  GET_COMPLETED_BOOKINGS_FAILURE: "GET_COMPLETED_BOOKINGS_FAILURE",

  GET_FAILED_BOOKINGS: "GET_FAILED_BOOKINGS",
  GET_FAILED_BOOKINGS_SUCCESS: "GET_FAILED_BOOKINGS_SUCCESS",
  GET_FAILED_BOOKINGS_FAILURE: "GET_FAILED_BOOKINGS_FAILURE",
};
