export const flightConstants = {
  SEARCH_FLIGHT_REQUEST: "SEARCH_FLIGHT_REQUEST",
  SEARCH_FLIGHT_SUCCESS: "SEARCH_FLIGHT_SUCCESS",
  SEARCH_FLIGHT_FAILURE: "SEARCH_FLIGHT_FAILURE",

  SEARCH_POLL_FLIGHT_REQUEST: "SEARCH_POLL_FLIGHT_REQUEST",
  SEARCH_POLL_FLIGHT_SUCCESS: "SEARCH_POLL_FLIGHT_SUCCESS",
  SEARCH_POLL_FLIGHT_FAILURE: "SEARCH_POLL_FLIGHT_FAILURE",

  REVALIDATE_FLIGHT_REQUEST: "REVALIDATE_FLIGHT_REQUEST",
  REVALIDATE_FLIGHT_SUCCESS: "REVALIDATE_FLIGHT_SUCCESS",
  REVALIDATE_FLIGHT_FAILURE: "REVALIDATE_FLIGHT_FAILURE",

  CHECKOUT_FLIGHT_REQUEST: "CHECKOUT_FLIGHT_REQUEST",
  CHECKOUT_FLIGHT_SUCCESS: "CHECKOUT_FLIGHT_SUCCESS",
  CHECKOUT_FLIGHT_FAILURE: "CHECKOUT_FLIGHT_FAILURE",

  GET_FLIGHT_BOOKING_DETAILS_REQUEST: "GET_FLIGHT_BOOKING_DETAILS_REQUEST",
  GET_FLIGHT_BOOKING_DETAILS_SUCCESS: "GET_FLIGHT_BOOKING_DETAILS_SUCCESS",
  GET_FLIGHT_BOOKING_DETAILS_FAILURE: "GET_FLIGHT_BOOKING_DETAILS_FAILURE",

  GET_STRIPE_INTENT_REQUEST: "GET_STRIPE_INTENT_REQUEST",
  GET_STRIPE_INTENT_SUCCESS: "GET_STRIPE_INTENT_SUCCESS",
  GET_STRIPE_INTENT_FAILURE: "GET_STRIPE_INTENT_FAILURE",
};
